<template>
  <div>
    <div v-if="loadingCourseInfo" class="text-center">
      <LoaderNew />
    </div>
    <div v-else style="display: flex; flex-direction: column; gap: 0.5rem">
      <div v-if="cs.courseInfo == null">Course not found</div>
      <div v-else style="display: flex; flex-direction: column; gap: 1rem">
        <div
          class="card"
          style="
            padding: 1rem;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
          "
        >
          <div>
            <h1>{{ (cs.courseInfo as CoursePublicInfo).info.title }}</h1>
          </div>
          <div>{{ (cs.courseInfo as CoursePublicInfo).info.description }}</div>
          <div>
            Comment words:
            {{ countValues("words") }}
          </div>
          <div>
            Annotation glyphs:
            {{ countValues("glyphs") }}
          </div>
          <div>
            Arrows:
            {{ countValues("arrows") }}
          </div>
          <div>
            Highlights:
            {{ countValues("highlights") }}
          </div>
          <div>
            <button
              class="btn btn-info"
              type="button"
              @click="goToCourseViewer()"
            >
              To the course
            </button>
          </div>
          <div style="margin-top: 1rem; width: 35rem">
            <table class="table table-striped table-responsive caption-top">
              <thead class="table-light">
                <tr>
                  <th scope="col" class="col-auto">Model games</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(game, index) in (cs.courseInfo as CoursePublicInfo).info.modelGames"
                  :key="index"
                >
                  <td>
                    <a href="#" @click.prevent="goToCourseViewer(game.id)">
                      {{ getGameVsText(game) }}
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import router from "@/router";
import LoaderNew from "@/components/util/LoaderNew.vue";
import { useCourseStore } from "@/stores/courseStore";
import { useToast } from "vue-toast-notification";
import type { CoursePublicInfo, ModelGame } from "@/types/apitypes";
import { dateToDateMonthWithOptionalYear, formatThousands } from "@/util/util";

const route = useRoute();
const cs = useCourseStore();

let loadingCourseInfo = ref(true);

onMounted(() => {
  // Load course info
  loadCourseInfo();
});

async function loadCourseInfo() {
  loadingCourseInfo.value = true;

  try {
    await useCourseStore().loadCourseInfo(courseId.value);
  } catch (e) {
    useToast().error("Failed to load course");
  }

  loadingCourseInfo.value = false;
}

const courseId = computed(() => route.params.courseid as string);

function goToCourseViewer(modelGameIndex: string | null = null) {
  let query = {};
  if (modelGameIndex != null) {
    query = { gameid: modelGameIndex };
  }

  router.push({
    name: "courseview",
    params: { courseid: courseId.value },
    query,
  });
}

function countValues(key: string): string {
  let map: any;
  switch (key) {
    case "words":
      return cs.courseInfo?.info.stats?.wordCount == null
        ? ""
        : formatThousands(cs.courseInfo.info.stats.wordCount);
    case "glyphs":
      map = cs.courseInfo?.info.stats?.nags.count;
      break;
    case "highlights":
      map = cs.courseInfo?.info.stats?.highlights.count;
      break;
    case "arrows":
      map = cs.courseInfo?.info.stats?.arrows.count;
      break;
    default:
      return "";
  }

  if (map == null) {
    return "";
  }

  // Sum the values of the map and return
  return formatThousands(
    Object.keys(map).reduce((acc, key) => acc + Number(map[key]), 0)
  );
}

function getGameVsText(game: ModelGame): string {
  let text = game.white;

  if (game.whiteElo != null) {
    text += ` (${game.whiteElo})`;
  }

  text += ` vs ${game.black}`;

  if (game.blackElo != null) {
    text += ` (${game.blackElo})`;
  }

  text += `, ${game.event}`;

  if (game.date != null) {
    text += `, (${dateToDateMonthWithOptionalYear(game.date, true)})`;
  }

  return text;
}
</script>

<style scoped>
.table th,
.table td {
  text-align: left;
  border: 1px solid #dee2e6;
}

.table th.col-auto,
.table td.col-auto {
  width: 1%;
  white-space: nowrap;
}

.table th.col,
.table td.col {
  width: 100%;
}
</style>
