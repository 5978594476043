<template>
  <div style="display: flex; gap: 2rem">
    <div>
      <label>Font size:</label>
      <div style="display: flex">
        <span style="font-size: 1.5rem; min-width: 4rem"
          >{{ (fontSize * 100).toFixed(0) }}%</span
        >
        <button class="btn btn-info small-button" @click="decreaseFontSize">
          -
        </button>
        <button class="btn btn-info small-button" @click="increaseFontSize">
          +
        </button>
      </div>
    </div>
    <div style="display: flex; flex-direction: column">
      <label>Font:</label>
      <div
        class="btn-group"
        role="group"
        aria-label="Button group with nested dropdown"
      >
        <div class="btn-group" role="group" style="width: 11rem">
          <a
            id="btnGroupDrop3"
            type="button"
            class="btn btn-info dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            :style="{ fontFamily: ps.courseSettings.fontFamily ?? 'inherit' }"
          >
            {{ ps.courseSettings.fontFamily ?? "Default" }}
          </a>
          <div class="dropdown-menu" aria-labelledby="btnGroupDrop3">
            <a
              v-for="font in fonts"
              :key="font"
              :value="font"
              class="dropdown-item"
              href="#"
              :style="{ fontFamily: font ?? 'inherit' }"
              @click="
                ps.courseSettings.fontFamily = font === 'Default' ? null : font
              "
              >{{ font }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { usePageStore } from "@/stores/pageStore";

// Access the Pinia store
const ps = usePageStore();

// Use a computed property for font size with a default of 1 if not set
const fontSize = computed({
  get: () => ps.courseSettings.fontSize ?? 1,
  set: (value: number) => {
    ps.courseSettings.fontSize = value;
  },
});

// Increase font size by 0.1 (10%)
const increaseFontSize = () => {
  fontSize.value = Number((fontSize.value + 0.1).toFixed(1));
};

// Decrease font size by 0.1 (10%)
// You might want to set a minimum to avoid negative values (here, a minimum of 0.1 is enforced)
const decreaseFontSize = () => {
  if (fontSize.value > 0.1) {
    fontSize.value = Number((fontSize.value - 0.1).toFixed(1));
  }
};

// Predefined list of font families
const fonts = [
  "Default",
  "Arial",
  "Helvetica",
  "Times New Roman",
  "Courier New",
  "Verdana",
  "cursive",
  "fantasy",
  "monospace",
  "serif",
  "sans-serif",
  "system-ui",
];
</script>

<style scoped>
.small-button {
  --bs-btn-font-size: 0.8rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-padding-y: 0.25rem;
}

div {
  margin-bottom: 1em;
}

label {
  margin-right: 0.5em;
}

button {
  margin: 0 0.2em;
}
</style>
