<template>
  <div>
    Filter on current position X<br />
    <br />
    Model games:<br />
    Game 1<br />
    Game 2<br />
    <br />
    Reference games:<br />
    Game 1<br />
  </div>
</template>

<script setup lang="ts"></script>

<style scoped></style>
