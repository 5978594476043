<template>
  <div id="text-spot" style="overflow-x: hidden; overflow-y: hidden">
    <i
      class="lose-puzzle-check-achieved lose-puzzle-check-icon fa-solid fa-circle-xmark"
      id="starFull"
    />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { usePageStore } from "@/stores/pageStore";

const start = () => {
  usePageStore().setPlaySound("failed-puzzle");

  let starFullElement = document.getElementById("starFull");
  starFullElement!.classList.add("lose-puzzle-rotate-scale-up-diag-1");

  starFullElement!.addEventListener("animationend", function () {
    starFullElement!.classList.remove("lose-puzzle-rotate-scale-up-diag-1");

    setTimeout(() => {
      starFullElement!.classList.add("lose-puzzle-rotate-out-center");
    }, 500);
  });
};

onMounted(() => {
  start();
});
</script>

<style scoped>
#text-spot {
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.lose-puzzle-check-icon {
  position: absolute;
  width: 10rem;
  height: 10rem;
  z-index: 10;
  font-size: 10rem;

  border-radius: 50%;
  --bs-btn-bg: var(--clr-rect-2);
  --bs-btn-border-color: var(--clr-rect-2);
  --bs-btn-hover-bg: var(--clr-rect-1);
  --bs-btn-hover-border-color: var(--clr-rect-1);
  --bs-btn-text: var(--clr-rect-1);
}

.lose-puzzle-check-achieved {
  background: white;
  color: var(--clr-rect-5);
}

.lose-puzzle-rotate-out-center {
  animation: lose-puzzle-rotate-out-center 0.6s ease-in-out both;
}
.lose-puzzle-rotate-scale-up-diag-1 {
  animation: lose-puzzle-rotate-scale-up-diag-1 0.3s linear both;
}
@keyframes lose-puzzle-rotate-out-center {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes lose-puzzle-rotate-scale-up-diag-1 {
  0% {
    transform: scale(300%);
    opacity: 0;
  }
  50% {
    transform: scale(200%);
  }
  100% {
    transform: scale(100%);
    opacity: 1;
  }
}
</style>
