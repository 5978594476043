<template>
  <div />
</template>

<script setup lang="ts">
import { onMounted, type PropType } from "vue";
import { usePageStore } from "@/stores/pageStore";
import type { GameTermination } from "@/types/apitypes";

const props = defineProps({
  termination: {
    type: Object as PropType<GameTermination>,
  },
});

const emit = defineEmits(["isRunning"]);

function start() {
  emit("isRunning", { isRunning: true });
  usePageStore().setPlaySound("draw");

  setTimeout(() => {
    emit("isRunning", { isRunning: false });
  }, 1000);
}

onMounted(() => {
  start();
});
</script>

<style scoped></style>
