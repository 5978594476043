<template>
  <div class="move-comment" :style="getVariationDecoration()">
    <!-- We render our computed VNode -->
    <component :is="decoratedComment" />
  </div>
</template>

<script setup lang="ts">
import { computed, h, ref } from "vue";
import type { PropType } from "vue";
import { useRouter } from "vue-router";
import { useCourseStore } from "@/stores/courseStore";

const props = defineProps({
  comment: {
    type: String,
    required: true,
  },
  variationType: {
    type: Array as PropType<string[]>,
    required: false,
  },
});

const router = useRouter();

// This is the function to call when the gear icon is clicked.
function goToCourseViewer(modelGameIndex: string | null = null) {
  let query = {};
  if (modelGameIndex != null) {
    query = { gameid: modelGameIndex };
  }
  router
    .push({
      name: "courseview",
      params: { courseid: useCourseStore().courseInfo?.shortName },
      query,
    })
    .then(() => {
      window.location.reload();
    });
}

function getVariationDecoration() {
  if (props.variationType?.includes("main")) {
    return {
      color: "var(--clr-main)",
    };
  }
  return {
    fontStyle: "italic",
    color: "var(--clr-main-light)",
  };
}

/**
 * Test whether the given token (with any trailing punctuation removed)
 * qualifies as a chess move.
 *
 * This function does not alter the original text.
 */
function isChessMove(token: string): boolean {
  // For testing purposes only, remove trailing commas, periods, or right parentheses.
  const testToken = token.replace(/[,.)]+$/, "");
  const moveRegex = new RegExp(
    "^(?:" +
      // Optional prefix: either a move number with 1–3 dots (e.g. "3." or "3...")
      // or an ellipsis ("...") on its own, possibly followed by spaces.
      "(?:(?:\\d+\\.{1,3}|\\.{3})\\s*)?" +
      "(?:" +
      // Castling moves: O-O/O-O-O or 0-0/0-0-0.
      "(?:O-O(?:-O)?|0-0(?:-0)?)" +
      "|" +
      // Standard move:
      // Optional piece letter (K, Q, R, B, N)
      "[KQRBN]?" +
      // Optional disambiguation: file and/or rank.
      "[a-h]?[1-8]?" +
      // Optional separator: hyphen or "x"
      "(?:[-x])?" +
      // Required destination square (file and rank)
      "[a-h][1-8]" +
      // Optional promotion (e.g., "=Q")
      "(?:=[QRBN])?" +
      ")" +
      // Optional check or mate symbols (+ or #)
      "(?:[+#])?" +
      // Optional annotation symbols (e.g., !, ? or their combinations)
      "(?:[!?]+)?" +
      ")$"
  );
  return moveRegex.test(testToken);
}

/**
 * Process a single token (a string with no whitespace) by searching for chess-move substrings.
 * If found, the token is split into:
 *   - a prefix (any text before the move),
 *   - the chess move itself (wrapped in a <move> tag),
 *   - a trailing part (such as punctuation) that is left unwrapped.
 *
 * If no chess move is found, the original token is returned as is.
 */
function processToken(token: string): Array<string | ReturnType<typeof h>> {
  const nodes: Array<string | ReturnType<typeof h>> = [];
  // The regex uses three capturing groups:
  // Group 1: An optional prefix that is a move-number (or ellipsis) with optional whitespace.
  // Group 2: The main chess move, either castling or standard.
  // Group 3: Any trailing punctuation we want to exclude from the <move> tag.
  //
  // This regex is global so that if more than one chess move occurs in the token,
  // we can decorate them all.
  const regex =
    /((?:\d+\.{1,3}|\.{3})\s*)?((?:O-O(?:-O)?|0-0(?:-0)?|[KQRBN]?[a-h]?[1-8]?(?:[-x])?[a-h][1-8](?:=[QRBN])?)(?:[+#])?(?:[!?]+)?)([,.)]+)?/g;

  let lastIndex = 0;
  // @ts-ignore
  for (const match of token.matchAll(regex)) {
    // match.index might be undefined, but we assume modern engines provide it.
    const start = match.index ?? 0;
    if (start > lastIndex) {
      // Push any text before this match unmodified.
      nodes.push(token.slice(lastIndex, start));
    }
    const prefix = match[1] || "";
    const movePart = match[2] || "";
    const trailing = match[3] || "";
    const fullMove = prefix + movePart;
    // Only wrap if the candidate (fullMove) passes our test.
    if (fullMove && isChessMove(fullMove)) {
      // Wrap the move part (without the trailing punctuation).
      nodes.push(h("move", fullMove));
      // Then append the trailing punctuation (if any) as plain text.
      if (trailing) {
        nodes.push(trailing);
      }
    } else {
      // If for some reason it doesn't qualify, output the entire match as is.
      nodes.push(match[0]);
    }
    lastIndex = start + match[0].length;
  }
  // Append any remaining text after the last match.
  if (lastIndex < token.length) {
    nodes.push(token.slice(lastIndex));
  }
  return nodes;
}

/**
 * Process a block of text by splitting on whitespace (preserving delimiters)
 * and also on "/" (which may separate multiple moves), and then running processToken()
 * on each segment.
 */
function processText(text: string): Array<string | ReturnType<typeof h>> {
  // Split by whitespace while keeping the delimiters.
  const tokens = text.split(/(\s+)/);
  const result: Array<string | ReturnType<typeof h>> = [];
  tokens.forEach((token) => {
    if (token.trim() === "") {
      result.push(token);
    } else if (token.includes("/")) {
      // If the token contains one or more "/" dividers, split and process each segment.
      const segments = token.split("/");
      segments.forEach((seg, idx) => {
        result.push(...processToken(seg));
        if (idx < segments.length - 1) {
          result.push("/");
        }
      });
    } else {
      result.push(...processToken(token));
    }
  });
  return result;
}

const decoratedComment = computed(() => {
  const parts: Array<string | ReturnType<typeof h>> = [];
  // Regex to find ***MODEL GAME X*** pattern.
  const modelGameRegex = /\*\*\*MODEL GAME (\d+)\*\*\*/g;
  let lastIndex = 0;
  let match: RegExpExecArray | null;

  // Loop through all occurrences.
  while ((match = modelGameRegex.exec(props.comment)) !== null) {
    const index = match.index;
    // Process any text before this match.
    if (index > lastIndex) {
      const before = props.comment.slice(lastIndex, index);
      parts.push(...processText(before));
    }
    // Extract the number from the match.
    const modelGameNumber = match[1];
    // Create the ModelGame element wrapping the leading text and icon.
    parts.push(
      h(
        "modelgame",
        { role: "button", onClick: () => goToCourseViewer(modelGameNumber) },
        [
          h("i", {
            class: "fa-solid fa-chess-board",
            style: { cursor: "pointer", marginRight: "0.2rem" },
          }),
          `Model game ${modelGameNumber} `,
        ]
      )
    );
    lastIndex = modelGameRegex.lastIndex;
  }
  // Process any text after the last match.
  if (lastIndex < props.comment.length) {
    const remaining = props.comment.slice(lastIndex);
    parts.push(...processText(remaining));
  }
  // Return a span that wraps all the parts.
  return h("span", parts);
});
</script>

<style scoped>
.move-comment {
  padding: 0.2rem 0.5rem;
}

/* You can style the <move> tag here */
move {
  font-weight: bold;
  color: var(--clr-accent2);
}

modelgame {
  font-weight: 800;
  color: var(--clr-accent);
}
</style>
