<template>
  <div style="margin: 50px auto"><BotProfileComponent :botId="botId" /></div>
</template>

<script setup lang="ts">
import { useRoute } from "vue-router";
import { ref, watch } from "vue";
import BotProfileComponent from "@/components/modals/BotProfileComponent.vue";
import { ImageType } from "@/types/internaltypes";
import { useBackgroundStore } from "@/stores/backgroundStore";
import { usePageStore } from "@/stores/pageStore";

const route = useRoute();
const backgroundStore = useBackgroundStore();
const ps = usePageStore();

let botId = ref(
  typeof route.params.botid === "string"
    ? route.params.botid
    : route.params.botid[0]
);

watch(
  () => botId.value,
  () => {
    backgroundStore.setBackground(
      ps.img(botId.value, ImageType.BotBackground, null)
    );
  },
  { immediate: true }
);
</script>
