<template>
  <div>
    <div style="display: flex; flex-direction: column; gap: 1rem">
      <div style="display: flex; justify-content: space-between">
        <div
          v-if="
            state == 'initialload' ||
            state == 'nocache' ||
            state == 'refreshingcache' ||
            state == 'refreshingcachenogames'
          "
        >
          <span
            class="loading-ellipsis"
            v-if="state == 'initialload' || state == 'nocache'"
            style="color: var(--clr-accent)"
            >Loading your stats</span
          >
          <span
            class="loading-ellipsis"
            style="color: var(--clr-accent)"
            v-if="
              state == 'refreshingcache' || state == 'refreshingcachenogames'
            "
          >
            Found new games, refreshing</span
          >
        </div>

        <div v-if="state == 'doneerror'">
          Something went wrong retrieving stats, try again in a while!
        </div>
        <div v-if="state == 'donenogames'"></div>
        <div v-if="state == 'done'"></div>
        <div
          class="btn-group"
          role="group"
          aria-label="Basic radio toggle button group"
        >
          <input
            type="radio"
            class="btn-check"
            name="timeframe"
            id="timeframe-week"
            autocomplete="off"
            :checked="chosenTimeframe === 'week'"
            @click="chosenTimeframe = 'week'"
            :disabled="state != 'done'"
          />
          <label
            class="btn btn-outline-info"
            for="timeframe-week"
            :style="{
              backgroundColor: chosenTimeframe === 'week' ? '' : 'white',
            }"
            >Week</label
          >
          <input
            type="radio"
            class="btn-check"
            name="timeframe"
            id="timeframe-month"
            autocomplete="off"
            :checked="chosenTimeframe === 'month'"
            @click="chosenTimeframe = 'month'"
            :disabled="state != 'done'"
          />
          <label
            class="btn btn-outline-info"
            for="timeframe-month"
            :style="{
              backgroundColor: chosenTimeframe === 'month' ? '' : 'white',
            }"
            >Month</label
          >
          <input
            type="radio"
            class="btn-check"
            name="timeframe"
            id="timeframe-year"
            autocomplete="off"
            :checked="chosenTimeframe === 'year'"
            @click="chosenTimeframe = 'year'"
            :disabled="state != 'done'"
          />
          <label
            class="btn btn-outline-info"
            for="timeframe-year"
            :style="{
              backgroundColor: chosenTimeframe === 'year' ? '' : 'white',
            }"
            >Year</label
          >
          <input
            type="radio"
            class="btn-check"
            name="timeframe"
            id="timeframe-all"
            autocomplete="off"
            :checked="chosenTimeframe === 'all'"
            @click="chosenTimeframe = 'all'"
            :disabled="state != 'done'"
          />
          <label
            class="btn btn-outline-info"
            for="timeframe-all"
            :style="{
              backgroundColor: chosenTimeframe === 'all' ? '' : 'white',
            }"
            >All</label
          >
        </div>
      </div>
      <div
        v-if="state == 'donenogames'"
        style="width: 100%; text-align: center"
      >
        <h3>You haven't played any games yet</h3>
        <a href="/" class="btn btn-info" role="button">Play some games</a>
      </div>
      <div
        v-if="state != 'donenogames'"
        style="
          display: flex;
          gap: 1rem;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        <div
          :class="
            'top-two' +
            (state == 'initialload' || state == 'nocache'
              ? ' pulsate-load-strong'
              : '')
          "
        >
          <div
            class="card"
            :style="{
              padding: '1rem',
              width: '100%',
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '1rem',
              flexGrow: '1',
              height: isMobileWidth() ? '50rem' : '38rem',
            }"
          >
            <h3>
              <i
                class="fa-duotone fa-arrow-trend-up"
                style="color: var(--clr-accent)"
              ></i>
              Rating
            </h3>
            <div class="rating-box">
              <div class="rating-box-boxes">
                <RatingsBox
                  :loading="state != 'done' && state != 'refreshingcache'"
                  :lowest-rating-date="
                    getRatingRecords(chosenTimeframe).lowestDate
                  "
                  :lowest-rating="getRatingRecords(chosenTimeframe).lowest"
                  :highest-rating-date="
                    getRatingRecords(chosenTimeframe).highestDate
                  "
                  :highest-rating="getRatingRecords(chosenTimeframe).highest"
                  :current-rating="
                    quickStats?.ratingInfo?.currentRating?.rating?.toFixed(0) ??
                    0
                  "
                />
                <div class="rating-box-box-boxes">
                  <InfoBox
                    :loading="state != 'done' && state != 'refreshingcache'"
                    :heading="'Change ' + chosenTimeframe"
                    :sub-heading="
                      getRatingChangeElement(
                        getRatingChange(
                          quickStats?.ratingInfo?.currentRating?.rating ?? 0,
                          ratingHistory,
                          chosenTimeframe,
                          quickStats?.ratingInfo?.historyChange
                        )
                      )
                    "
                    v-tippy="{
                      content:
                        'How much your rating has changed in the selected timeframe',
                    }"
                  />
                  <InfoBox
                    :loading="state != 'done' && state != 'refreshingcache'"
                    :heading="'Change 24 hours'"
                    :sub-heading="
                      getRatingChangeElement(
                        getRatingChangeDiff(
                          quickStats?.ratingInfo?.currentRating?.rating,
                          quickStats?.ratingInfo?.historyChange?.lastDay
                        )
                      )
                    "
                    v-tippy="{
                      content:
                        'How much your rating has changed in the last 24 hours',
                    }"
                  />
                </div>
              </div>
              <div style="width: 100%; height: 100%">
                <RatingChart
                  :loading="state != 'done' && state != 'refreshingcache'"
                  :initial-zoom="30"
                  :ratingHistory="ratingHistory"
                  :timeframe="chosenTimeframe"
                />
              </div>
            </div>
          </div>
          <div style="width: 100%">
            <div
              class="card"
              :style="{
                padding: '1rem',
                width: '100%',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '3rem',
                height: isMobileWidth() ? '34rem' : '38rem',
              }"
            >
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                "
              >
                <h3>
                  <i
                    class="fa-solid fa-chess"
                    style="color: var(--clr-accent)"
                  ></i>
                  Games
                </h3>
                <span>(only rated games)</span>
              </div>
              <HorizontalBar
                :loading="state != 'done' && state != 'refreshingcache'"
                :wins="stats.allGames.wins"
                :draws="stats.allGames.draws"
                :losses="stats.allGames.losses"
              />
              <div style="display: flex; gap: 0.5rem; flex-direction: column">
                <div class="game-box-boxes">
                  <InfoBox
                    :loading="state != 'done' && state != 'refreshingcache'"
                    heading="Games played"
                    :sub-heading="stats.allGames.total + ''"
                    v-tippy="{
                      content: 'Total games played',
                    }"
                  />

                  <InfoBox
                    :loading="state != 'done' && state != 'refreshingcache'"
                    heading="Games/day"
                    :sub-heading="
                      getGamesPerDay(
                        stats.allGames.total,
                        chosenTimeframe,
                        ratingHistory
                      )
                    "
                    v-tippy="{
                      content: 'Games played per day on average',
                    }"
                  />

                  <InfoBox
                    :loading="state != 'done' && state != 'refreshingcache'"
                    heading="Current win streak"
                    :sub-heading="quickStats?.ratingInfo?.winStreak ?? 0"
                    v-tippy="{
                      content: 'Current wins row',
                    }"
                  />
                </div>
                <div class="game-box-boxes">
                  <InfoBox
                    :loading="state != 'done' && state != 'refreshingcache'"
                    heading="Win/loss"
                    :sub-heading="
                      getWinLossText(stats.allGames.wins, stats.allGames.losses)
                    "
                    v-tippy="{
                      content:
                        'Win loss ratio, how many wins compared to losses (draws not counted)',
                    }"
                  />
                  <InfoBox
                    :loading="state != 'done' && state != 'refreshingcache'"
                    heading="Days in a row"
                    :sub-heading="
                      getGamesInARow(ratingHistory).consecutiveDaysWithEntries
                    "
                    v-tippy="{
                      content: 'Rated games days in a row',
                    }"
                  />
                  <InfoBox
                    :loading="state != 'done' && state != 'refreshingcache'"
                    heading="Longest break"
                    :sub-heading="
                      getGamesInARow(ratingHistory).consecutiveMissingDays
                    "
                    v-tippy="{
                      content: 'Most days without rated games in a row',
                    }"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="
            state == 'initialload' || state == 'nocache'
              ? ' pulsate-load-strong'
              : ''
          "
          style="display: flex; flex-direction: column; width: 100%"
        >
          <div
            class="card"
            style="
              padding: 1rem;
              text-align: center;
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 1rem;
              width: 100%;
            "
          >
            <h3>
              <i
                class="fa-solid fa-web-awesome"
                style="color: var(--clr-accent)"
              ></i>
              Bot facts
            </h3>
            <span v-if="stats.allGames.total == 0"
              >Play some more games to see more stats here</span
            >
            <div class="bots-container">
              <InfoStyleBox
                :loading="state != 'done' && state != 'refreshingcache'"
                heading="Beaten bots"
                :sub-heading="useUserStore().user.data?.beatenBots?.length ?? 0"
                styleName="star"
                :footer="'\u00A0'"
                v-tippy="{
                  content: 'Number of different beaten bots',
                }"
              />
              <InfoStyleBox
                :loading="state != 'done' && state != 'refreshingcache'"
                heading="Daily matchup"
                :sub-heading="
                  useUserStore().user.data?.dailyMatchupStreaks?.best ?? 0
                "
                styleName="check"
                :footer="'\u00A0'"
                v-tippy="{
                  content: 'Highest daily matchup win streak',
                }"
              />
              <TopBotsTop
                v-if="stats.favoriteBots.length > 0"
                :loading="state != 'done' && state != 'refreshingcache'"
                :bots="stats.favoriteBots"
                v-tippy="{
                  content: 'Top 5 bots played against',
                }"
              />
              <InfoBotBox
                v-if="stats.equalBots.length > 0"
                :loading="state != 'done' && state != 'refreshingcache'"
                heading="Equal match"
                :sub-heading="equalBotText(stats.equalBots[0])"
                :botId="stats.equalBots[0].botId"
                :footer="
                  '(' +
                  stats.equalBots[0].wins +
                  ' wins, ' +
                  stats.equalBots[0].losses +
                  ' losses)'
                "
                v-tippy="{
                  content:
                    'The bot you have the most equal score against, score is wins - losses',
                }"
              />
              <InfoBotBox
                v-if="stats.nemesisBots.length > 0"
                :loading="state != 'done' && state != 'refreshingcache'"
                heading="Nemesis"
                :sub-heading="
                  (stats.nemesisBots[0].winRate * 100).toFixed(1) + ' %'
                "
                :botId="stats.nemesisBots[0].botId"
                :footer="
                  '(' +
                  stats.nemesisBots[0].wins +
                  ' wins, ' +
                  stats.nemesisBots[0].losses +
                  ' losses)'
                "
                v-tippy="{
                  content:
                    'A bot you just can\'t seem to get the upper hand against, the worst win rate',
                }"
              />

              <InfoBotBox
                v-if="stats.easyBots.length > 0"
                :loading="state != 'done' && state != 'refreshingcache'"
                heading="Pushover"
                :sub-heading="
                  (stats.easyBots[0].winRate * 100).toFixed(1) + ' %'
                "
                :botId="stats.easyBots[0].botId"
                :footer="
                  '(' +
                  stats.easyBots[0].wins +
                  ' wins, ' +
                  stats.easyBots[0].losses +
                  ' losses)'
                "
                v-tippy="{
                  content:
                    'The bot you seem to have the easiest time against, the best win rate',
                }"
              />

              <InfoBotBox
                v-if="stats.bestWin?.rating != null"
                :loading="state != 'done' && state != 'refreshingcache'"
                heading="Best win"
                :sub-heading="stats.bestWin.rating + ' rating'"
                :botId="stats.bestWin.botId"
                :footer="'\u00A0'"
                v-tippy="{
                  content: 'The highest rated bot you\'ve beaten',
                }"
              />

              <InfoBotBox
                v-if="stats.worstLoss?.rating != null"
                :loading="state != 'done' && state != 'refreshingcache'"
                heading="Worst loss"
                :sub-heading="stats.worstLoss.rating + ' rating'"
                :botId="stats.worstLoss.botId"
                :footer="'\u00A0'"
                v-tippy="{
                  content: 'The lowest rated bot you\'ve lost against',
                }"
              />
              <TopPlaystyles
                v-if="stats.favoritePlaystyles.length > 0"
                :loading="state != 'done' && state != 'refreshingcache'"
                :games="stats.favoritePlaystyles"
                v-tippy="{
                  content: 'Games against the different bot playstyles',
                }"
              />
              <InfoStyleBox
                v-if="stats.bestPlaystyle != null"
                :loading="state != 'done' && state != 'refreshingcache'"
                heading="Best style"
                :sub-heading="
                  (stats.bestPlaystyle.winRate * 100).toFixed(1) + ' %'
                "
                :styleName="stats.bestPlaystyle.playstyle.name"
                :footer="`(${stats.bestPlaystyle.wins} wins, ${stats.bestPlaystyle.losses} losses)`"
                v-tippy="{
                  content:
                    'The type of bot you have the highest win rate against',
                }"
              />
              <InfoStyleBox
                v-if="stats.worstPlaystyle != null"
                :loading="state != 'done' && state != 'refreshingcache'"
                heading="Worst style"
                :sub-heading="
                  (stats.worstPlaystyle.winRate * 100).toFixed(1) + ' %'
                "
                :styleName="stats.worstPlaystyle.playstyle.name"
                :footer="`(${stats.worstPlaystyle.wins} wins, ${stats.worstPlaystyle.losses} losses)`"
                v-tippy="{
                  content:
                    'The type of bot you have the lowest win rate against',
                }"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUserStore } from "@/stores/userStore";
import { ref, watch } from "vue";
import TopBotsTop from "@/components/stats/TopBotsTop.vue";
import HorizontalBar from "@/components/stats/HorizontalBar.vue";
import RatingChart from "@/components/stats/RatingChart.vue";
import InfoBox from "@/components/stats/InfoBox.vue";
import InfoBotBox from "@/components/stats/InfoBotBox.vue";
import InfoStyleBox from "@/components/stats/InfoStyleBox.vue";
import { isMobileWidth } from "@/util/util";
import TopPlaystyles from "@/components/stats/TopPlaystyles.vue";
import { useBotsStore } from "@/stores/botStore";
import { personaCategories } from "@/util/personplaycategory";
import RatingsBox from "@/components/stats/RatingsBox.vue";

let state = ref<
  | "initialload"
  | "nocache"
  | "refreshingcache"
  | "refreshingcachenogames"
  | "done"
  | "doneerror"
  | "donenogames"
>("initialload");

let ratingRecords = ref();
let ratingHistory = ref();
let chosenTimeframe = ref<"week" | "month" | "year" | "all">("all");

let rawStats = ref();

let quickStats = ref();

let stats = ref({
  allGames: {
    total: 0,
    wins: 0,
    draws: 0,
    losses: 0,
  },
  winLossRatio: 0.0,
  drawRate: 0.0,
  favoriteBots: [
    {
      botId: "",
      total: 0,
      wins: 0,
      draws: 0,
      losses: 0,
    },
  ],
  easyBots: [
    {
      botId: "",
      winRate: 0.0,
      total: 0,
      wins: 0,
      draws: 0,
      losses: 0,
    },
  ],
  nemesisBots: [
    {
      botId: "",
      winRate: 0.0,
      total: 0,
      wins: 0,
      draws: 0,
      losses: 0,
    },
  ],
  equalBots: [
    {
      botId: "",
      winRate: 0.0,
      score: 0,
      total: 0,
      wins: 0,
      draws: 0,
      losses: 0,
    },
  ],
  bestWin: {
    botId: "",
    rating: 0,
  },
  worstLoss: {
    botId: "",
    rating: 0,
  },
  favoritePlaystyles: [
    {
      playstyle: {
        id: 0,
        name: "",
        tooltip: "",
        bg: "",
        img: "",
      },
      winRate: 0,
      total: 0,
      wins: 0,
      draws: 0,
      losses: 0,
    },
  ],
  bestPlaystyle: {
    playstyle: {
      id: 0,
      name: "",
      tooltip: "",
      bg: "",
      img: "",
    },
    total: 0,
    winRate: 0.0,
    wins: 0,
    draws: 0,
    losses: 0,
  },
  worstPlaystyle: {
    playstyle: {
      id: 0,
      name: "",
      tooltip: "",
      bg: "",
      img: "",
    },
    total: 0,
    winRate: 0.0,
    wins: 0,
    draws: 0,
    losses: 0,
  },
  winRateAgainstLowerRated: 0.0,
  winRateAgainstHigherRated: 0.0,
});

watch(chosenTimeframe, () => {
  parseStats();
});

getStats();

function getStats() {
  Promise.all([useUserStore().getStats(), useUserStore().getQuickStats()]).then(
    ([userStatsResponse, quickStatsResponse]) => {
      quickStats.value = quickStatsResponse;

      if (userStatsResponse.failure) {
        state.value = "doneerror";
      } else if (userStatsResponse.poll) {
        if (userStatsResponse.stats == null) {
          state.value = "nocache";
        } else {
          rawStats.value = JSON.parse(userStatsResponse.stats);
          ratingRecords.value = JSON.parse(userStatsResponse.ratingRecords)[0];
          if (rawStats.value.length == 0) {
            state.value = "refreshingcachenogames";
          } else {
            parseStats();
            ratingHistory.value = userStatsResponse.ratingHistory;
            state.value = "refreshingcache";
          }
        }
        setTimeout(getStats, 1000);
      } else {
        rawStats.value = JSON.parse(userStatsResponse.stats);
        ratingRecords.value = JSON.parse(userStatsResponse.ratingRecords)[0];
        if (rawStats.value.length == 0) {
          state.value = "donenogames";
        } else {
          parseStats();
          ratingHistory.value = userStatsResponse.ratingHistory;
          state.value = "done";
        }
      }
    }
  );
}

function getWinLossText(wins: number, losses: number) {
  if (wins == 0 && losses == 0) {
    return "-";
  } else if (wins == 0) {
    return "0%";
  } else if (losses == 0) {
    return "100%";
  }

  return (ratioPercent(wins, losses) * 100).toFixed(1) + "%";
}

function equalBotText(bot: any) {
  if (bot.score > 0) {
    return "+" + bot.score + " score";
  }

  if (bot.score < 0) {
    return bot.score + " score";
  }

  return "±0 score";
}

function getRatingRecords(timeframe: "week" | "month" | "year" | "all"): {
  highest: string;
  highestDate: string;
  lowest: string;
  lowestDate: string;
} {
  if (ratingRecords.value == null) {
    return {
      highest: "-",
      highestDate: "",
      lowest: "-",
      lowestDate: "",
    };
  }

  let highest;
  let highestDate;
  let lowest;
  let lowestDate;

  if (timeframe == "week") {
    highest = ratingRecords?.value?.highest_rating_7d ?? "-";
    highestDate =
      highest == "-"
        ? ""
        : ratingRecords?.value?.highest_rating_7d_ended_at?.value?.split(
            "T"
          )[0] ?? "";
    lowest = ratingRecords?.value?.lowest_rating_7d ?? "-";
    lowestDate =
      lowest == "-"
        ? ""
        : ratingRecords?.value?.lowest_rating_7d_ended_at?.value?.split(
            "T"
          )[0] ?? "";
  } else if (timeframe == "month") {
    highest = ratingRecords?.value?.highest_rating_30d ?? "-";
    highestDate =
      highest == "-"
        ? ""
        : ratingRecords?.value?.highest_rating_30d_ended_at?.value?.split(
            "T"
          )[0] ?? "";
    lowest = ratingRecords?.value?.lowest_rating_30d ?? "-";
    lowestDate =
      lowest == "-"
        ? ""
        : ratingRecords?.value?.lowest_rating_30d_ended_at?.value?.split(
            "T"
          )[0] ?? "";
  } else if (timeframe == "year") {
    highest = ratingRecords?.value?.highest_rating_365d ?? "-";
    highestDate =
      highest == "-"
        ? ""
        : ratingRecords?.value?.highest_rating_365d_ended_at?.value?.split(
            "T"
          )[0] ?? "";
    lowest = ratingRecords?.value?.lowest_rating_365d ?? "-";
    lowestDate =
      lowest == "-"
        ? ""
        : ratingRecords?.value?.lowest_rating_365d_ended_at?.value?.split(
            "T"
          )[0] ?? "";
  } else {
    highest = ratingRecords?.value?.highest_rating_all ?? "-";
    highestDate =
      highest == "-"
        ? ""
        : ratingRecords?.value?.highest_rating_all_ended_at?.value?.split(
            "T"
          )[0] ?? "";
    lowest = ratingRecords?.value?.lowest_rating_all ?? "-";
    lowestDate =
      lowest == "-"
        ? ""
        : ratingRecords?.value?.lowest_rating_all_ended_at?.value?.split(
            "T"
          )[0] ?? "";
  }

  return {
    highest,
    highestDate,
    lowest,
    lowestDate,
  };
}

function getRatingChangeDiff(
  rating1: number | undefined,
  rating2: number | undefined
) {
  if (rating1 == null || rating2 == null) {
    return 0;
  }
  return Math.round(rating1 - rating2);
}

function getRatingChange(
  currentRating: number,
  ratingHistory: any,
  timeframe: string,
  change:
    | {
        lastGame: number | null;
        lastDay: number | null;
        lastWeek: number | null;
        lastMonth: number | null;
      }
    | undefined
) {
  if (ratingHistory == null || ratingHistory.length == 0 || change == null) {
    return 0;
  }
  if (timeframe == "week") {
    if (change.lastWeek == null) return "-";
    return Math.round(currentRating - change.lastWeek!);
  } else if (timeframe == "month") {
    if (change.lastMonth == null) return "-";
    return Math.round(currentRating - change.lastMonth!);
  }

  let sortedRatings = ratingHistory.daily.sort((a: any, b: any) => {
    return a.started_at._seconds - b.started_at._seconds;
  });

  let firstDay = new Date(sortedRatings[0].started_at._seconds * 1000);
  let aYearAgoMillis = new Date().getTime() - 1000 * 60 * 60 * 24 * 365;

  if (timeframe == "all" || firstDay.getTime() > aYearAgoMillis) {
    return Math.round(currentRating - ratingHistory.daily[0].rating_new);
  }

  // Find the first rating within the last year
  for (let i = 0; i < sortedRatings.length; i++) {
    let date = new Date(sortedRatings[i].started_at._seconds * 1000);
    if (date.getTime() > aYearAgoMillis) {
      return Math.round(currentRating - sortedRatings[i].rating_new);
    }
  }

  // Should really not get here
  return 0;
}

function ratioPercent(value1: number, value2: number) {
  let ratio = value1 / (value1 + value2);
  if (ratio > 1) {
    ratio = 1;
  } else if (ratio < 0) {
    ratio = 0;
  }
  return parseFloat(ratio.toFixed(3));
}

function parseStats() {
  if (rawStats.value == null) {
    console.error("No stats to parse");
    return;
  }

  let parsedRawStats = rawStats.value.map((item: any) => {
    const result: any = { bot_id: item.bot_id }; // Start with the bot_id
    const fields = ["games", "wins", "losses", "draws"];

    // Loop through the fields and assign the appropriate timeframe value
    fields.forEach((field) => {
      result[field] = item[`${field}_${chosenTimeframe.value}`]; // Access the field dynamically
    });

    return result;
  });

  let all = parsedRawStats.find((s: any) => s.bot_id == null);

  stats.value.allGames = {
    total: all.games,
    wins: all.wins,
    draws: all.draws,
    losses: all.losses,
  };

  const validBots = parsedRawStats.filter((bot: any) => bot.bot_id !== null);

  // Favorite bots
  stats.value.favoriteBots = validBots
    .filter((bot: any) => bot.games > 0) // Keep only bots with games played
    .sort((a: any, b: any) => b.games - a.games) // Sort in descending order by games
    .map((bot: any) => {
      return {
        botId: bot.bot_id,
        total: bot.games,
        wins: bot.wins,
        draws: bot.draws,
        losses: bot.losses,
      };
    })
    .slice(0, 5); // Get the first 5 e

  // Easy bots
  stats.value.easyBots = validBots
    .filter((bot: any) => bot.games > 0) // Keep only bots with games played
    .sort((a: any, b: any) => {
      const ratioA = ratioPercent(a.wins, a.losses);
      const ratioB = ratioPercent(b.wins, b.losses);
      return ratioB - ratioA; // Sort by win ratio in descending order
    })
    .map((bot: any) => {
      return {
        botId: bot.bot_id,
        winRate: ratioPercent(bot.wins, bot.losses),
        total: bot.games,
        wins: bot.wins,
        draws: bot.draws,
        losses: bot.losses,
      };
    })
    .slice(0, 5);

  // Nemesis bots
  stats.value.nemesisBots = validBots
    .filter((bot: any) => bot.games > 0) // Keep only bots with games played
    .sort((a: any, b: any) => {
      const ratioA = ratioPercent(a.wins, a.losses);
      const ratioB = ratioPercent(b.wins, b.losses);
      return ratioA - ratioB; // Sort by win ratio in descending order
    })
    .map((bot: any) => {
      return {
        botId: bot.bot_id,
        winRate: ratioPercent(bot.wins, bot.losses),
        total: bot.games,
        wins: bot.wins,
        draws: bot.draws,
        losses: bot.losses,
      };
    })
    .slice(0, 5);

  stats.value.equalBots = validBots
    .filter((bot: any) => bot.games > 0) // Keep only bots with games played
    .sort((a: any, b: any) => {
      // This is the plus minus score, but also takes the draws into account, so many draws puts the bot higher in equal list
      const scoreA = Math.abs(a.wins - a.losses) - a.draws;
      const scoreB = Math.abs(b.wins - b.losses) - b.draws;
      return scoreA - scoreB; // Sort by score, ascending
    })
    .map((bot: any) => {
      return {
        botId: bot.bot_id,
        winRate: ratioPercent(bot.wins, bot.losses),
        score: bot.wins - bot.losses,
        total: bot.games,
        wins: bot.wins,
        draws: bot.draws,
        losses: bot.losses,
      };
    })
    .slice(0, 5);

  let playStylesData: any = {};
  let personaCategoires = personaCategories();
  validBots
    .filter((bot: any) => bot.games > 0)
    .forEach((bot: any) => {
      let playstyle = useBotsStore().getBot(bot.bot_id)?.strength
        .playstyle_category;

      if (playstyle != null) {
        if (playStylesData[playstyle] == null) {
          playStylesData[playstyle] = {
            playstyle: playstyle,
            total: 0,
            wins: 0,
            draws: 0,
            losses: 0,
          };
        }
        playStylesData[playstyle].total += bot.games;
        playStylesData[playstyle].wins += bot.wins;
        playStylesData[playstyle].draws += bot.draws;
        playStylesData[playstyle].losses += bot.losses;
      }
    });

  function getObjectValues(obj: Record<string, any>): any[] {
    const valuesArray: any[] = [];
    for (const key in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(key)) {
        valuesArray.push(obj[key]);
      }
    }
    return valuesArray;
  }
  stats.value.favoritePlaystyles = getObjectValues(playStylesData)
    .map((playstyle: any) => {
      return {
        playstyle: personaCategoires.find(
          (p: any) => p.id == playstyle.playstyle
        )!,
        winRate: ratioPercent(playstyle.wins, playstyle.losses),
        total: playstyle.total,
        wins: playstyle.wins,
        draws: playstyle.draws,
        losses: playstyle.losses,
      };
    })
    .sort((a: any, b: any) => a.playstyle.id - b.playstyle.id);

  stats.value.bestPlaystyle = JSON.parse(
    JSON.stringify(stats.value.favoritePlaystyles)
  ).sort((a: any, b: any) => b.winRate - a.winRate)[0];

  stats.value.worstPlaystyle = JSON.parse(
    JSON.stringify(stats.value.favoritePlaystyles)
  ).sort((a: any, b: any) => a.winRate - b.winRate)[0];

  stats.value.bestWin = validBots
    .filter(
      (bot: any) => bot.wins > 0 && useBotsStore().getBot(bot.bot_id) != null
    )
    .map((bot: any) => {
      return {
        botId: bot.bot_id,
        rating: useBotsStore().getBot(bot.bot_id).strength.estimated_elo,
      };
    })
    .sort((a: any, b: any) => {
      return b.rating - a.rating;
    })[0];

  stats.value.worstLoss = validBots
    .filter(
      (bot: any) => bot.wins > 0 && useBotsStore().getBot(bot.bot_id) != null
    )
    .map((bot: any) => {
      return {
        botId: bot.bot_id,
        rating: useBotsStore().getBot(bot.bot_id).strength.estimated_elo,
      };
    })
    .sort((a: any, b: any) => {
      return a.rating - b.rating;
    })[0];
}

function getGamesInARow(ratingHistory: any): {
  consecutiveDaysWithEntries: number;
  consecutiveMissingDays: number;
} {
  if (ratingHistory == null) {
    return {
      consecutiveDaysWithEntries: 0,
      consecutiveMissingDays: 0,
    };
  }

  let filterMillis = 1;

  if (chosenTimeframe.value == "week") {
    filterMillis = new Date().getTime() - 1000 * 60 * 60 * 24 * 7;
  } else if (chosenTimeframe.value == "month") {
    filterMillis = new Date().getTime() - 1000 * 60 * 60 * 24 * 30;
  } else if (chosenTimeframe.value == "year") {
    filterMillis = new Date().getTime() - 1000 * 60 * 60 * 24 * 365;
  }

  let orderedDaysWithRating = JSON.parse(JSON.stringify(ratingHistory.daily))
    .filter((r: any) => r.started_at._seconds * 1000 > filterMillis)
    .map((r: any) => {
      return new Date(r.started_at._seconds * 1000);
    })
    .sort((a: any, b: any) => a.getTime() - b.getTime());

  // Step 2: Calculate the starting date based on daysAgo
  const today = new Date();
  const startDate = new Date();
  startDate.setDate(today.getDate() - 365);

  let currentStreak = 0;
  let currentMissingStreak = 0;

  let maxConsecutiveDaysWithEntries = 0;
  let maxConsecutiveMissingDays = 0;

  // Step 3: Iterate from startDate to today, checking for gaps and streaks
  let lastEntryDate: Date | null = null;
  let foundFirstEntry = false;

  for (let d = new Date(startDate); d <= today; d.setDate(d.getDate() + 1)) {
    const isEntryFound = orderedDaysWithRating.some(
      (entryDate: Date) => entryDate.toDateString() === d.toDateString()
    );

    if (!foundFirstEntry && !isEntryFound) {
      continue;
    }

    if (isEntryFound) {
      foundFirstEntry = true;
      // Consecutive days with entries found
      currentStreak += 1;
      maxConsecutiveDaysWithEntries = Math.max(
        maxConsecutiveDaysWithEntries,
        currentStreak
      );
      currentMissingStreak = 0; // Reset missing streak
    } else {
      // Missing day found
      currentMissingStreak += 1;
      maxConsecutiveMissingDays = Math.max(
        maxConsecutiveMissingDays,
        currentMissingStreak
      );

      if (lastEntryDate) {
        currentStreak = 0; // Reset the streak when a missing day is found
      }
    }

    lastEntryDate = d; // Update the last checked day
  }

  return {
    consecutiveDaysWithEntries: maxConsecutiveDaysWithEntries,
    consecutiveMissingDays: maxConsecutiveMissingDays,
  };
}

function getGamesPerDay(
  games: number,
  timeframe: "week" | "month" | "year" | "all",
  ratingHistory: any
) {
  if (ratingHistory == null) {
    return 0;
  }

  function daysFromNow(date: Date) {
    const timeDifference = date.getTime() - new Date().getTime();
    const daysDifference = Math.abs(
      Math.ceil(timeDifference / (1000 * 3600 * 24))
    );
    return daysDifference;
  }

  let daysInPeriod;
  switch (timeframe) {
    case "week":
      daysInPeriod = 7;
      break;
    case "month":
      daysInPeriod = 30;
      break;
    case "year":
      daysInPeriod = 365;
      break;
    case "all":
    default:
      daysInPeriod =
        daysFromNow(
          new Date(ratingHistory.daily[0].started_at._seconds * 1000)
        ) + 1;
  }

  return (games / daysInPeriod).toFixed(1);
}

function getRatingChangeElement(ratingChange: "-" | number) {
  if (ratingChange == "-") {
    return "-";
  }
  let color = ratingChange >= 0 ? "--clr-accent4" : "--clr-rect-3";
  let plusSign = ratingChange >= 0 ? "+" : "";
  let icon = ratingChange >= 0 ? "fa-arrow-trend-up" : "fa-arrow-trend-down";

  return `<span style="color: var(${color})"><i class="fa-duotone ${icon}"></i> ${plusSign}${ratingChange}</span>`;
}
</script>

<style scoped>
.top-two {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
}

.bots-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: center;
}
.rating-box {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
  height: 100%;
}

.rating-box-boxes {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 13rem;
  flex-shrink: 0; /* Prevent shrinking */
  flex-grow: 0; /* Prevent growing */
}

.rating-box-box-boxes {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.rating-box-box-boxes > div {
  max-width: none !important;
  width: 100%;
}

.game-box-boxes {
  display: flex;
  gap: 0.5rem;
}

.game-box-boxes > div {
  max-width: none !important;
  width: 100%;
}

@media (max-width: 1200px) {
  .top-two {
    flex-direction: column;
    align-items: center;
  }
  .rating-box {
    flex-direction: column;
  }

  .rating-box-boxes {
    width: 100%;
  }

  .rating-box-box-boxes {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
  }

  .rating-box-box-boxes > div {
    max-width: none !important;
    width: 100%;
  }
}
</style>
