<template>
  <div>
    <div v-if="loadingGame" class="text-center">
      <LoaderNew />
    </div>
    <div v-else class="courseContainer">
      <div class="left">
        <div style="display: flex; flex-direction: column; width: 100%">
          <div
            style="
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 0.5rem;
              flex: 1;
            "
          >
            <div style="display: flex; width: 100%">
              <ul class="nav nav-tabs" style="flex: 1" role="tablist">
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link active ph-no-capture"
                    data-bs-toggle="tab"
                    href="#"
                    aria-selected="false"
                    role="tab"
                    tabindex="-1"
                    @click="
                      () => {
                        setSelectedSection('board');
                        track(
                          'course_view_view',
                          'set_selected_section',
                          'click',
                          {
                            section: 'board',
                          }
                        );
                      }
                    "
                    >Board</a
                  >
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link ph-no-capture"
                    data-bs-toggle="tab"
                    href="#"
                    aria-selected="true"
                    role="tab"
                    @click="
                      () => {
                        setSelectedSection('games');
                        track(
                          'course_view_view',
                          'set_selected_section',
                          'click',
                          {
                            section: 'games',
                          }
                        );
                      }
                    "
                    >Games</a
                  >
                </li>
                <li
                  class="nav-item"
                  role="presentation"
                  style="margin-left: auto"
                >
                  <a
                    class="nav-link ph-no-capture"
                    data-bs-toggle="tab"
                    href="#"
                    aria-selected="true"
                    role="tab"
                    style="color: var(--clr-accent); padding-inline: 0.5rem"
                    @click="
                      () => {
                        setSelectedSection('share');
                        track(
                          'course_view_view',
                          'set_selected_section',
                          'click',
                          {
                            section: 'share',
                          }
                        );
                      }
                    "
                    ><i class="fa-solid fa-share-from-square"></i
                  ></a>
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link ph-no-capture"
                    data-bs-toggle="tab"
                    href="#"
                    aria-selected="true"
                    role="tab"
                    style="color: var(--clr-accent); padding-inline: 0.5rem"
                    @click="
                      () => {
                        setSelectedSection('settings');
                        track(
                          'course_view_view',
                          'set_selected_section',
                          'click',
                          {
                            section: 'settings',
                          }
                        );
                      }
                    "
                    ><i class="fa-solid fa-gear"></i
                  ></a>
                </li>
              </ul>
            </div>

            <div style="width: 100%">
              <div v-if="selectedSection == 'share'">
                <ShareCoursePosition />
              </div>
              <div v-if="selectedSection == 'settings'"><CourseSettings /></div>
              <div v-if="selectedSection == 'games'">
                <CourseGameExplorer />
              </div>
              <div v-if="selectedSection == 'board'">
                <div
                  class="boardcontainer card expose-item-left"
                  style="margin: 0"
                >
                  <TheChessboard
                    :board-config="boardConfig"
                    @board-created="boardCreated"
                    @move="moveMade"
                  />
                </div>

                <EngineBox :fen="currentBoardFen" />
              </div>
            </div>
          </div>
          <div class="nav-button">
            <div>
              <a
                role="button"
                style="color: var(--clr-main-lighter)"
                @click="
                  router.push({
                    name: 'course',
                    params: { courseid: 'scandi' },
                  })
                "
                ><i class="fa-solid fa-arrow-left"></i> Course overview</a
              >
            </div>
          </div>
        </div>
      </div>
      <div
        class="right expose-item-right"
        style="display: flex; flex-direction: column; height: 80vh"
      >
        <ModelGameHeader
          v-if="cs.gameHeaderData != null && preselectedGame != null"
          :currentGameIndex="preselectedGame"
        />

        <TreeMainLine style="flex: 1; min-height: 0" />

        <div style="display: flex; gap: 0.5rem">
          <MoveNavigationCourse
            :has-prev="hasPrev"
            :next-moves="nextMoves"
            :has-alternatives="cs.alternatives.length > 1"
          />
          <CurrentCourseLine
            @next-partial="(uid: any) => (preferredAlternativeUid = uid.uid)"
          />
        </div>
      </div>
    </div>
    <SoundsStorage />
  </div>
</template>

<script setup lang="ts">
import TreeMainLine from "@/components/common/tree/TreeMainLine.vue";
import { useCourseStore } from "@/stores/courseStore";
import LoaderNew from "@/components/util/LoaderNew.vue";
import { BoardApi, type DrawShape, TheChessboard } from "vue3-chessboard";
import SoundsStorage from "@/components/sounds/SoundsStorage.vue";
import { computed, nextTick, onMounted, reactive, ref, watch } from "vue";
import { usePageStore } from "@/stores/pageStore";
import MoveNavigationCourse from "@/components/common/tree/MoveNavigationCourse.vue";
import CurrentCourseLine from "@/components/common/tree/CurrentCourseLine.vue";
import type { Key } from "chessground/types";
import { extractCalTags, extractCslTags, getNagSvg } from "@/util/course";
import EngineBox from "@/components/engine/EngineBox.vue";
import { useRoute } from "vue-router";
import ModelGameHeader from "@/components/common/tree/ModelGameHeader.vue";
import { track } from "@/util/tracking";
import CourseGameExplorer from "@/components/common/tree/CourseGameExplorer.vue";
import router from "@/router";
import CourseSettings from "@/components/common/tree/CourseSettings.vue";
import ShareCoursePosition from "@/components/common/tree/ShareCoursePosition.vue";

const cs = useCourseStore();
const route = useRoute();

let chessBoardBg = ref("#3cbfe0");
setBoardBg();

let chessboardOpacity = ref(1);

let loadingGame = ref(true);

let boardApi = ref<BoardApi | null>(null);
let nextMoves = ref<string[] | null>(null);
let hasPrev = ref(true);
let currentShapes: { type: string; shape: DrawShape }[] = [];
let currentBoardFen = ref<string>();
let preferredAlternativeUid = ref<number | null>(null);
let preselectedNode = ref<number | null>(null);
let preselectedGame = ref<string | null>(null);
let selectedSection = ref<"board" | "games" | "settings" | "share">("board");

const courseId = computed(() => route.params.courseid as string);

let boardConfig = reactive({
  blockTouchScroll: true,
  coordinates: usePageStore().showBoardCoordinates,
  orientation: "black" as "white" | "black" | undefined,
  premovable: { enabled: false },
  drawable: { enabled: true, visible: true },
});

watch(
  () => cs.selectedNode,
  () => {
    nodeChanged();
  },
  { immediate: true }
);

watch(
  () => cs.manualViewing,
  () => {
    if (cs.manualViewing) {
      setManualViewing();
    } else {
      stopManualViewing();
    }
  },
  { immediate: true }
);

watch(
  () => cs.alternatives,
  () => {
    if (cs.alternatives.length > 0) {
      let selectedFrom: string = "";
      let selectedTo: string = "";
      nextMoves.value = cs.alternatives.map((a) => {
        if (a.selected) {
          selectedFrom = a.uci.substring(0, 2);
          selectedTo = a.uci.substring(2, 4);
        }
        return a.san;
      });
      if (
        boardApi.value != null &&
        cs.choseAlternative != null &&
        selectedFrom != "" &&
        selectedTo != ""
      ) {
        // Clean previous alternatives
        currentShapes = currentShapes.filter((s) => s.type != "alternative");
        // Add this alternative arrow
        currentShapes.push({
          type: "alternative",
          shape: {
            orig: selectedFrom as Key,
            dest: selectedTo as Key,
            brush: "paleGrey",
          },
        });
        drawShapes();
      }
    } else {
      nextMoves.value = null;
    }
  },
  { deep: true, immediate: true }
);

onMounted(() => {
  preselectedNode.value =
    route.query.uid != null ? Number(route.query.uid) : null;
  preselectedGame.value =
    route.query.gameid != null ? (route.query.gameid as string) : null;
  init();
});

function drawShapes() {
  if (boardApi.value == null) {
    return;
  }
  nextTick().then(() => {
    boardApi.value!.setShapes(currentShapes.map((s) => s.shape));
  });
}

async function init() {
  let calls = [];
  if (preselectedGame.value != null) {
    calls.push(cs.loadCourse(courseId.value, "model", preselectedGame.value));
  } else {
    calls.push(cs.loadCourse(courseId.value, "main"));
  }

  calls.push(cs.loadCourseInfo(courseId.value));

  await Promise.all(calls);

  loadingGame.value = false;
  if (preselectedNode.value != null) {
    cs.selectMove(preselectedNode.value);
  }
}

function boardCreated(api: BoardApi) {
  boardApi.value = api;
}

function setBoardBg(lineType: string = "") {
  let color;
  if (lineType === "alternative") {
    color = "var(--clr-linebg-alternative)";
  } else if (lineType === "dubious") {
    color = "var(--clr-linebg-caution)";
  } else {
    color = usePageStore().boardColorOverride.active
      ? usePageStore().boardColorOverride.color
      : "#3cbfe0";
  }

  chessBoardBg.value = color;
  return color;
}

function scrollTo(uid: number) {
  try {
    let container = document.getElementById("move-tree");
    let anchor = document.getElementById("move-tree-move-" + uid.toString()); // Adjust the selector to target the anchor you want.
    // @ts-ignore
    let anchorPosition = anchor.offsetTop;
    // @ts-ignore
    let containerCenter = container.offsetHeight / 4;
    // @ts-ignore
    let newScrollTop =
      // @ts-ignore
      anchorPosition - containerCenter + anchor.offsetHeight / 4;
    // @ts-ignore
    container.scrollTop = newScrollTop;
  } catch (e) {
    // Ignore, it seems that sometimes there's a race condition here where the anchor element isn't yet rendered or so, just don't scroll then it's not the end of the world
  }
}

function moveMade() {
  cs.manualViewing = true;
  let startMoveNumber;
  try {
    startMoveNumber = cs.selectedNode?.fen.split(" ")[5];
  } catch (e) {
    // Something went wrong getting the currently selected fen (probably no current node) so just default to 1
    startMoveNumber = 1;
  }

  if (startMoveNumber == null) {
    startMoveNumber = 1;
  }

  let boardFen = boardApi.value!.getFen().split(" ");
  boardFen[5] = startMoveNumber;
  currentBoardFen.value = boardFen.join(" ");
}

function setManualViewing() {
  chessboardOpacity.value = 0.9;
}

function stopManualViewing() {
  chessboardOpacity.value = 1;
  nodeChanged();
}

function nodeChanged() {
  currentShapes = [];
  if (cs.selectedNode != null) {
    nextTick().then(() => {
      scrollTo(cs.selectedNode!.uid);
    });

    if (cs.selectedNode.type.includes("alternative")) {
      setBoardBg("alternative");
    } else if (cs.selectedNode.type.includes("dubious")) {
      setBoardBg("dubious");
    } else {
      // Set the board to the default color
      setBoardBg();
    }

    if (
      cs.selectedNode?.comments != null &&
      cs.selectedNode.comments.length > 0
    ) {
      currentShapes.push(
        ...extractCslTags(cs.selectedNode.comments.join(" ")).map((c) => ({
          type: "csl",
          shape: c,
        })),
        ...extractCalTags(cs.selectedNode.comments.join(" ")).map((c) => ({
          type: "cal",
          shape: c,
        }))
      );
      if (currentShapes.length > 0) {
        drawShapes();
      }
    }

    const moveNag =
      cs.selectedNode?.nags?.find((num: number) => num >= 1 && num <= 6) ??
      null;

    const toSquare = cs.selectedNode?.uci?.substring(2, 4);

    const customSvgContainer = document.querySelector(".cg-custom-svgs");

    if (customSvgContainer) {
      // We can't set this to completely every or the code doesn't work for some strange reason. The <g></g> is there from the start, and if it's not there at some point it will break and not add correctly I guess chessground is somehow relying on it or so
      // And also always clear before adding, since we always only want one NAG on the board, so any previous NAG should be cleared before adding a new one
      customSvgContainer.innerHTML = "<g></g>";
      if (moveNag != null && toSquare != null) {
        // TODO get the orientation from the course settings instead
        customSvgContainer.innerHTML += getNagSvg(toSquare, "b", moveNag);
      }
    }
  }

  hasPrev.value = cs.selectedNode != null;

  if (boardApi.value != null) {
    let newFen = cs.selectedNode?.fen;
    if (newFen == null) {
      newFen = "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1";
    }
    boardApi.value!.setPosition(newFen);

    if (cs.selectedNode?.uci != null) {
      // @ts-ignore setConfig doesn't work since it resets the shapes for some reason, so have to bypass and call board directly
      boardApi.value!.board.set({
        lastMove: [
          cs.selectedNode?.uci.substring(0, 2),
          cs.selectedNode?.uci.substring(2, 4),
        ],
      });
    }

    currentBoardFen.value = newFen;
  }
}

function setSelectedSection(section: "board" | "games" | "settings" | "share") {
  selectedSection.value = section;
}
</script>

<style scoped>
.active {
  background: linear-gradient(
    315deg,
    var(--clr-background-detail) 0%,
    var(--clr-accent) 100%
  );
}

.courseContainer {
  display: grid;
  grid-template-columns: 1fr auto 50rem 1fr;
  gap: 1rem;
}

.left {
  grid-column: 2;
  flex: 1 0;
  display: flex;
  justify-content: end;
  width: 396px;
}

.right {
  grid-column: 3;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.boardcontainer {
  width: 396px;
  height: 396px;
  position: relative;
  display: flex;
  margin-right: 1rem;
}

@media (max-width: 1200px) {
  .courseContainer {
    display: flex; /* Use flexbox for a single-column layout */
    flex-direction: column; /* Stack elements vertically */
    gap: 1rem; /* Maintain spacing between items */
  }

  .left {
    grid-column: unset; /* Reset any grid placement */
    width: 100%; /* Full width */
    max-width: 100%; /* Remove the width limitation */
    justify-content: center; /* Center the content */
    align-items: center; /* Optional: center-align items */
    display: flex; /* Ensure flex styles apply */
    flex-direction: column; /* Align items in a column */
  }

  .right {
    grid-column: unset; /* Reset any grid placement */
    width: 100%; /* Full width */
    flex: 0 0 auto; /* Allow it to shrink dynamically */
    max-width: 100%; /* Remove the width limitation */
    margin-top: 0; /* Adjust spacing if needed */
    display: flex; /* Ensure flex styles apply */
    flex-direction: column; /* Align items in a column */
    align-items: center; /* Optional: center-align items */
  }

  .move-tree {
    width: 100%; /* Ensure it spans the full width */
    max-width: none; /* Remove width constraint */
    height: 100vh;
    overflow-y: auto; /* Keep scrollable if content overflows */
  }

  .boardcontainer {
    margin: 0 auto; /* Center the chessboard horizontally */
    width: 100%; /* Optional: Adjust for mobile layout */
    height: auto; /* Maintain responsiveness */
  }
}

:deep(cg-board) {
  opacity: v-bind("chessboardOpacity");
  background-color: v-bind(
    "chessBoardBg"
  ); /* Override the board background color */
}
</style>
