import { defineStore } from "pinia";
import { usePageStore } from "@/stores/pageStore";

// If the background is changed, it's picked up as a mutation in App.vue and set on the body element
export const useBackgroundStore = defineStore("background", {
  state: () => ({
    background: null as string | null,
  }),
  actions: {
    setBackground(newBackground: string | null) {
      if (!usePageStore().showBotBackgrounds) {
        // If user has disabled bot backgrounds, set it null which is just the default background color
        this.background = null;
      } else {
        this.background = newBackground;
      }
    },
  },
});
