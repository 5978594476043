<template>
  <div
    class="card"
    style="
      display: flex;
      flex-direction: row;
      align-items: start;
      padding: 1rem;
    "
  >
    <div
      style="
        display: flex;
        align-items: center;
        flex-grow: 1;
        justify-content: center;
      "
    >
      <div style="display: flex; align-items: center; flex-direction: column">
        <div>
          <span class="" style="padding: 0 0.5rem">
            <span style="display: inline-block; transform: translateY(2px)"
              >Model Game {{ currentGameIndex }}</span
            >
          </span>
        </div>

        <div
          style="
            display: flex;
            flex-direction: row;
            align-items: start;
            gap: 0rem;
          "
        >
          <span
            class="nowrap-name"
            style="font-size: 1.5em; color: var(--clr-main)"
          >
            {{ modelGame.player1.name }}
            <span style="color: var(--clr-main-lighter)">{{
              modelGame.player1.elo != ""
                ? "(" + modelGame.player1.elo + ")"
                : ""
            }}</span>
          </span>
          <span
            style="
              font-size: 1.5em;
              color: var(--clr-main-lighter);
              margin: 0 0.5rem;
            "
            >vs</span
          >
          <span
            class="nowrap-name"
            style="font-size: 1.5em; color: var(--clr-main)"
          >
            {{ modelGame.player2.name }}
            <span style="color: var(--clr-main-lighter)">{{
              modelGame.player2.elo != ""
                ? "(" + modelGame.player2.elo + ")"
                : ""
            }}</span>
          </span>
        </div>
        <span
          style="
            margin-left: 1rem;
            font-size: 0.9em;
            color: var(--clr-main-lighter);
          "
        >
          ({{ modelGame.eventText }} {{ modelGame.dateText }})</span
        >
      </div>
    </div>
    <div
      style="
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;
      "
    >
      <div
        style="
          display: flex;
          align-items: center;
          gap: 0.5rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
        "
      >
        <div style="display: flex; gap: 0.5rem">
          <div class="nav-button">
            <div>
              <button
                class="btn btn-info"
                @click="goToCourseViewer(currentGameIndex - 1)"
                :disabled="currentGameIndex - 1 <= 0"
              >
                <i class="fa-solid fa-caret-left"></i>
              </button>
            </div>
            <span>Previous</span>
          </div>
          <div class="nav-button">
            <div>
              <button
                class="btn btn-info"
                @click="goToCourseViewer(currentGameIndex + 1)"
                :disabled="currentGameIndex + 1 > cs.courseInfo!.info.modelGames!.length"
              >
                <i class="fa-solid fa-caret-right"></i>
              </button>
            </div>
            <span>Next</span>
          </div>
          <div class="nav-button">
            <div>
              <button class="btn btn-info" @click="goToCourseViewer()">
                <i class="fa-solid fa-share fa-flip-horizontal"></i>
              </button>
            </div>
            <span>To course</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { useCourseStore } from "@/stores/courseStore";
import router from "@/router";

const cs = useCourseStore();

defineProps({
  currentGameIndex: { type: Number, required: true },
});

let modelGame = ref({
  player1: { name: "", elo: "" },
  player2: { name: "", elo: "" },
  eventText: "",
  dateText: "",
});

onMounted(() => {
  if (cs.gameHeaderData != null) {
    modelGame.value.player1.name = cs.gameHeaderData.get("White") ?? "";
    modelGame.value.player1.elo = cs.gameHeaderData.get("WhiteElo") ?? "";
    modelGame.value.player2.name = cs.gameHeaderData.get("Black") ?? "";
    modelGame.value.player2.elo = cs.gameHeaderData.get("BlackElo") ?? "";
    modelGame.value.eventText = cs.gameHeaderData.get("Event") ?? "";
    modelGame.value.dateText = formatPgnDate(cs.gameHeaderData.get("Date"));
  }
});

function formatPgnDate(pgnDate: string | undefined): string {
  if (pgnDate === "" || pgnDate == null) {
    return "";
  }

  // PGN dates are typically in "YYYY.MM.DD" format.
  // If any component is missing or unknown (e.g. "??"), we return the original string.
  const parts = pgnDate.split(".");
  if (parts.length !== 3 || parts.includes("??")) {
    return pgnDate;
  }
  const [yearStr, monthStr, dayStr] = parts;
  const year = Number(yearStr);
  const month = Number(monthStr) - 1; // JavaScript Date months are zero-based.
  const day = Number(dayStr);

  if (isNaN(year) || isNaN(month) || isNaN(day)) {
    return pgnDate;
  }

  const dateObj = new Date(year, month, day);
  // Use toLocaleDateString with options for short month, numeric day and year.
  return dateObj.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
}

function goToCourseViewer(modelGameIndex: number | null = null) {
  let query = {};
  if (modelGameIndex != null) {
    query = { gameid: modelGameIndex };
  }

  router
    .push({
      name: "courseview",
      params: { courseid: cs.courseInfo!.shortName },
      query,
    })
    .then(() => {
      // Force a full page reload after navigation
      window.location.reload();
    });
}
</script>

<style scoped>
.nowrap-name {
  max-width: 20rem;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.nav-button {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  width: 4rem;
  white-space: nowrap;
  color: var(--clr-main-lighter);
}
</style>
