<template>
  <div
    style="
      margin: 0rem 0 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    "
  >
    <span style="font-size: 1.5rem; color: white">Let's play!</span>
    <span style="color: white">Choose your first opponent</span>
    <div
      :style="{
        display: 'flex',
        width: '100%',
        gap: isMobileWidth(1400) ? '0.5rem' : '5rem',
        margin: '1rem 0',
        justifyContent: 'center',
      }"
    >
      <div
        v-for="(b, i) in introBots"
        :key="'introBot' + i"
        style="
          display: flex;
          flex-direction: column;
          align-items: center;
          color: #43ccff;
        "
      >
        <span>{{ b.difficulty }}</span>
        <ProfileCircleWithPlaque
          :bot="b.bot"
          :imageSize="isMobileWidth(1400) ? 6 : 9"
          style="margin-bottom: 1.5rem"
          role="button"
          @click="
            () => {
              if (b.bot != null) {
                ms.showBotProfile(b.bot.id);
                track('play_box', 'profile_card_image', 'click');
              }
            }
          "
        />

        <button
          :class="'btn btn-info' + (loading ? '' : ' bounce-top')"
          :style="{
            padding: '0.2rem 0.5rem',
            fontSize: isMobileWidth(1400) ? '1rem' : '1.2rem',
            whiteSpace: 'nowrap',
            visibility: loading ? 'hidden' : 'visible',
          }"
          @click="
            () => {
              if (b.bot != null) {
                startGame(b.bot);
                track('play_box', 'intro_' + b.difficulty, 'click');
              }
            }
          "
        >
          <i class="fa-duotone fa-swords" /> Play now
        </button>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useBotsStore } from "@/stores/botStore";
import { ImageType } from "@/types/internaltypes";
import { track } from "@/util/tracking";
import { useUserStore } from "@/stores/userStore";
import { useRouter } from "vue-router";
import { usePageStore } from "@/stores/pageStore";
import { getViewportSize, isMobileWidth } from "@/util/util";
import ConfettiExplosion from "vue-confetti-explosion";
import { useModalsStore } from "@/stores/modalsStore";
import ProfileCircleWithPlaque from "@/components/common/ProfileCircleWithPlaque.vue";
import type { Bot } from "@/types/apitypes";
import { onMounted, ref } from "vue";

const router = useRouter();
const us = useUserStore();
const bs = useBotsStore();
const ps = usePageStore();
const ms = useModalsStore();

let loading = ref(true);
let introBots = ref([
  { botId: "UCQPF", bot: null as Bot | null, difficulty: "Beginner" },
  { botId: "cjgqx", bot: null as Bot | null, difficulty: "Intermediate" },
  { botId: "tvgBZ", bot: null as Bot | null, difficulty: "Advanced" },
]);

async function fetchBotProfiles() {
  const botPromises = introBots.value.map(async (introBot) => {
    const botProfile = await bs.getUserBotProfile(introBot.botId);
    introBot.bot = botProfile.data.bot;
  });

  await Promise.all(botPromises);
  loading.value = false;
}

onMounted(() => {
  fetchBotProfiles();
});

function startGame(bot: Bot) {
  router.push({
    name: "game",
    query: {
      bid: bot.id,
      type: "casual",
      auto: "true",
    },
  });
}
</script>
<style scoped>
.profile-image-circle {
  width: 7rem;
  aspect-ratio: 1;
  z-index: 10;
  border-radius: 50%;
  border: 1.5px solid #444;
}

.profile-name-text {
  position: absolute;
  bottom: -1rem;
  padding: 0 0.5rem;
}

.bot-and-name {
  position: relative;
  display: flex;
  justify-content: center;
  margin: auto;
  z-index: 100;
  margin-bottom: 1.5rem;
}

.bot-and-name:hover {
  filter: drop-shadow(0.1rem 0.1rem 0.2rem #000);
}
</style>
