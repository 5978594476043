<template>
  <div
    v-show="show"
    class="card information-container bounce-top"
    style="--bs-card-border-color: var(--clr-accent); margin-bottom: 1.5rem"
  >
    <div class="main">
      <div
        style="display: flex; flex-direction: column; gap: 1rem; width: 100%"
      >
        <span
          >👋 Our bots are due for a rating update, and you can help simply by
          answering this question!
        </span>
        <span style="font-size: 1.2rem"
          >What rating <i class="fa-duotone fa-arrow-trend-up" /> do you have
          outside of Chessiverse?</span
        >
        <div style="display: flex; flex-wrap: wrap; gap: 0.2rem">
          <button
            v-for="rating in [
              '400 or less',
              '500',
              '600',
              '700',
              '800',
              '900',
              '1000',
              '1100',
              '1200',
              '1300',
              '1400',
              '1500',
              '1600',
              '1700',
              '1800',
              '1900',
              '2000',
              '2100',
              '2200 or more',
              'I don\'t know',
            ]"
            class="btn btn-info"
            :key="'askforrating' + rating"
            @click="
              () => {
                setRating(rating);
                track('ask_for_rating_notification', 'select_rating', 'click', {
                  rating: rating,
                });
              }
            "
          >
            {{ rating }}
          </button>
        </div>
        <span>(any platform, we're mainly looking for rough estimates)</span>
      </div>
      <div>
        <a
          role="button"
          class="ph-no-capture"
          @click="
            () => {
              dismissBanner();
              track('dashboard', 'dismiss_going_premmium_banner', 'click');
            }
          "
          ><i class="fa-regular fa-xmark-circle close-x"
        /></a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUserStore } from "@/stores/userStore";
import { ref } from "vue";
import { track } from "@/util/tracking";
import { parseDate } from "@/util/util";
import { useToast } from "vue-toast-notification";

const userStore = useUserStore();

let show = ref(shouldShow());

function shouldShow() {
  if (
    useUserStore().user.data?.actions?.includes(
      "dismissedAskForRatingBanner"
    ) ||
    localStorage.getItem("dismissedAskForRatingBanner") == "true"
  ) {
    return false;
  }

  if (useUserStore().user.data?.givenRating != null) {
    return false;
  }

  // @ts-ignore createdAt does exist
  let createdAt = parseDate(useUserStore().user.firebaseUser?.createdAt);

  if (createdAt == null) {
    return false;
  }
  let fourteenDaysAgo = new Date();
  fourteenDaysAgo.setDate(fourteenDaysAgo.getDate() - 14);

  // Don't show the banner if the user is too new
  if (createdAt > fourteenDaysAgo) {
    return false;
  }

  return true;
}

function dismissBanner() {
  show.value = false;
  localStorage.setItem("dismissedAskForRatingBanner", "true");
}

function setRating(rating: string) {
  useUserStore().setGivenRating(rating, "ask_for_rating_notification");
  dismissBanner();
  useToast().success("Thank you!");
}
</script>

<style scoped>
.information-container {
  width: 100%;
  padding: 0.5rem 0.5rem 0.5rem 2rem;
}

.main {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.close-x {
  color: var(--clr-accent);
}

.close-x:hover {
  color: var(--clr-accent2);
}
</style>
