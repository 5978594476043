<template>
  <div style="display: flex; flex-direction: column; gap: 0.5rem; width: 100%">
    <div style="display: flex; flex-direction: column">
      <span>Link to course position:</span>
      <div style="display: flex; gap: 0.5rem">
        <input
          style="flex: 1"
          v-model="currentShareLink"
          @click="selectElement"
        />
        <button
          v-tippy="{
            content: 'Copy link',
          }"
          class="btn btn-info ph-no-capture"
          @click="
            () => {
              copyShare(currentShareLink);
              track('share_course_position_modal', 'copy_share_link', 'click');
            }
          "
        >
          <i class="fa-regular fa-copy"></i>
        </button>
      </div>
    </div>
    <div style="display: flex; flex-direction: column">
      <span>Position FEN:</span>
      <div style="display: flex; gap: 0.5rem">
        <input style="flex: 1" v-model="currentFen" @click="selectElement" />
        <button
          v-tippy="{
            content: 'Copy FEN',
          }"
          class="btn btn-info ph-no-capture"
          @click="
            () => {
              copyFen(currentFen);
              track('share_course_position_modal', 'copy_fen', 'click');
            }
          "
        >
          <i class="fa-regular fa-copy"></i>
        </button>
      </div>
    </div>
    <div style="display: flex; flex-direction: column">
      <span>Current line:</span>
      <div style="display: flex; gap: 0.5rem">
        <input style="flex: 1" v-model="currentLine" @click="selectElement" />
        <button
          v-tippy="{
            content: 'Copy line',
          }"
          class="btn btn-info ph-no-capture"
          @click="
            () => {
              copyLine(currentLine);
              track('share_course_position_modal', 'copy_line', 'click');
            }
          "
        >
          <i class="fa-regular fa-copy"></i>
        </button>
      </div>
    </div>
    <div style="display: flex; flex-direction: column">
      <span>Analyze:</span>
      <a
        :href="getLichessUrl() ?? ''"
        target="_blank"
        class="btn btn-info ph-no-capture"
        :class="getLichessUrl() == null ? 'disabled' : ''"
        @click="track('moves_box', 'lichess_analysis', 'click')"
      >
        <svg
          viewBox="0 0 50 50"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          stroke="currentColor"
          height="1rem"
        >
          <path
            stroke-linejoin="round"
            d="M38.956.5c-3.53.418-6.452.902-9.286 2.984C5.534 1.786-.692 18.533.68 29.364 3.493 50.214 31.918 55.785 41.329 41.7c-7.444 7.696-19.276 8.752-28.323 3.084C3.959 39.116-.506 27.392 4.683 17.567 9.873 7.742 18.996 4.535 29.03 6.405c2.43-1.418 5.225-3.22 7.655-3.187l-1.694 4.86 12.752 21.37c-.439 5.654-5.459 6.112-5.459 6.112-.574-1.47-1.634-2.942-4.842-6.036-3.207-3.094-17.465-10.177-15.788-16.207-2.001 6.967 10.311 14.152 14.04 17.663 3.73 3.51 5.426 6.04 5.795 6.756 0 0 9.392-2.504 7.838-8.927L37.4 7.171z"
          />
        </svg>
        <span style="margin-left: 0.5rem">Analyze on Lichess</span>
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useModalsStore } from "@/stores/modalsStore";
import { track } from "@/util/tracking";
import { getCurrentSanLineFromUidPath } from "@/util/course";
import { useCourseStore } from "@/stores/courseStore";
import { useToast } from "vue-toast-notification";
import { computed, ref, watch } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();

const cs = useCourseStore();
const ms = useModalsStore();

const currentFen = computed(() => cs.selectedNode?.fen);
const currentShareLink = computed(() => {
  let base = `${window.location.origin}${route.path}`;

  if (cs.selectedNode?.uid != null) {
    base += `?uid=${cs.selectedNode.uid}`;
  }

  return base;
});
const currentLine = computed(() => getCurrentLine());

function getCurrentLine(): string {
  const sanLine = getCurrentSanLineFromUidPath(
    cs.findNodePath(cs.selectedNode?.uid)
  );
  let result = "";

  for (let i = 0; i < sanLine.length; i++) {
    // Add the move number for every white move (odd index in 1-based counting)
    if (i % 2 === 0) {
      result += `${Math.floor(i / 2) + 1}. `;
    }
    result += `${sanLine[i].san} `;

    // Add comments, leaving this here commented so we can extract lines later if we want
    // if (sanLine[i].comments) {
    //   result += `{${sanLine[i].comments.join(" ")}} `;
    // }
  }

  return result.trim(); // Trim the trailing space
}

function getLichessUrl(): string | null {
  let movesString = getCurrentSanLineFromUidPath(
    cs.findNodePath(cs.selectedNode?.uid)
  )
    .map((m) => m.san)
    .join(" ");

  // TODO The color should come from the course setttings in the future
  return "https://lichess.org/analysis/pgn/" + movesString + "?color=black";
}

function selectElement(event: Event) {
  const input = event.target as HTMLInputElement;
  input.select();
}

async function copyLine(line: string | null | undefined) {
  await copy(line, "Line copied");
}

async function copyShare(shareLink: string | null | undefined) {
  await copy(shareLink, "Link copied");
}

async function copyFen(fen: string | null | undefined) {
  await copy(fen, "FEN copied");
}

async function copy(string: string | null | undefined, successMessage: string) {
  if (string == null) {
    return;
  }

  await navigator.clipboard.writeText(string);
  useToast().success(successMessage);
}
</script>

<style scoped></style>
