<template>
  <div style="display: flex; flex-direction: column; width: 100%">
    <div style="display: flex; align-items: start; gap: 0.5rem">
      <Toggle
        style="margin-top: 0.2rem"
        v-model="engineOn"
        onLabel="Engine"
        offLabel="Engine"
      />
      <div
        v-if="
          engineOn &&
          (stockfish == null || !stockfishLoaded || currentEvals.length == 0)
        "
        style="display: flex; gap: 1rem; align-items: center"
      >
        <LoaderNew />
      </div>

      <div
        style="flex-grow: 1"
        v-if="
          engineOn &&
          stockfish != null &&
          stockfishLoaded &&
          currentEvals.length > 0
        "
      >
        <span style="font-size: 1.5rem; width: 5rem"
          >{{ cpToString(currentEvals[0].cp) }}
          {{ currentEvals[0].moves[0] }}</span
        >
      </div>
      <div
        v-if="
          engineOn &&
          stockfish != null &&
          stockfishLoaded &&
          currentEvals.length > 0
        "
        style="margin-top: 0.25rem"
      >
        <span
          style="
            font-size: 0.8rem;

            color: var(--clr-main-lighter);
          "
          >Stockfish 16.1 | Depth {{ currentEvals[0].depth }} |
          {{ npsToString(currentEvals[0].nps) }}</span
        >
      </div>
    </div>
    <span
      v-for="(evalLine, index) in currentEvals"
      :key="'eval' + index"
      class="eval-line"
      ><span style="font-size: 1.1rem">{{ cpToString(evalLine.cp) }}</span>
      {{ movesToLine(fen, evalLine.moves) }}</span
    >
  </div>
</template>

<script setup lang="ts">
import { onBeforeUnmount, ref, watch } from "vue";
import { Chess } from "chess.js";
import Toggle from "@vueform/toggle";
import LoaderNew from "@/components/util/LoaderNew.vue";

const props = defineProps({
  fen: {
    type: String,
    default: "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1",
  },
});

let engineOn = ref(false);
let stockfish: Worker | null = null;
let stockfishLoaded = ref(false); // Indicates whether the worker is ready
let currentEvals = ref<
  {
    cp: number;
    nps: number;
    depth: number;
    seldepth: number;
    moves: string[];
  }[]
>([]);

watch(
  [() => props.fen, () => engineOn.value],
  async () => {
    currentEvals.value = []; // Clear the current evals
    if (!engineOn.value) {
      if (stockfish) {
        unloadStockfish();
      }
      return;
    }
    if (!stockfish) {
      initializeStockfish();
    }
    await waitForStockfish(); // Ensure the worker is initialized
    sendCommand("uci"); // Sending UCI so we can wait for the response before listening to new moves again
    sendCommand("ucinewgame");
    sendCommand(`position fen ${props.fen}`);
    sendCommand("go infinite");
  },
  { immediate: true }
);

function initializeStockfish() {
  if (!stockfish) {
    stockfish = new Worker("/stockfish-16.1-lite-single.js");
    stockfish.onmessage = (event: MessageEvent) => {
      if (event.data === "uciok") {
        stockfishLoaded.value = true; // Mark worker as ready
      } else if (
        event.data.startsWith("info depth") &&
        event.data.includes(" pv ") &&
        !event.data.includes("upperbound") &&
        !event.data.includes("lowerbound")
      ) {
        const evalOutput = parseEvalOutput(event.data);
        if (evalOutput) {
          currentEvals.value[0] = evalOutput;
        }
      }
    };
    sendCommand("uci"); // Trigger the UCI initialization
  }
}

function npsToString(nps: number): string {
  if (nps < 1000) {
    return `${nps} n/s`; // Less than 1000: no decimals, just the number
  } else if (nps < 100_000) {
    let knps = nps / 1000;
    return `${knps.toFixed(knps < 10 ? 1 : 0)} Kn/s`; // Between 1000 and 1,000,000: convert to kilo with 1 decimal point
  } else {
    let mnps = nps / 1_000_000;
    return `${mnps.toFixed(mnps < 10 ? 1 : 0)} Mn/s`; // 1,000,000 or more: convert to mega with 1 decimal point
  }
}

function cpToString(cp: number): string {
  let fullPawn = (cp / 100).toFixed(2);

  let sign = cp < 0 ? "" : "+";

  return `${sign}${fullPawn}`;
}

function parseEvalOutput(output: string): {
  cp: number;
  nps: number;
  depth: number;
  seldepth: number;
  moves: string[];
} | null {
  try {
    const cpMatch = output.match(/score cp (-?\d+)/);
    const npsMatch = output.match(/nps (-?\d+)/);
    const depthMatch = output.match(/depth (-?\d+)/);
    const seldepthMatch = output.match(/seldepth (-?\d+)/);
    const pvMatch = output.split(" pv ")[1];

    if (!cpMatch || !pvMatch || !npsMatch || !depthMatch || !seldepthMatch) {
      return null; // Return null if either cp or pv data is missing
    }

    let cp = parseInt(cpMatch[1], 10); // Extract and parse cp value
    const nps = parseInt(npsMatch[1], 10); // Extract and parse cp value
    const depth = parseInt(depthMatch[1], 10); // Extract and parse cp value
    const seldepth = parseInt(seldepthMatch[1], 10); // Extract and parse cp value

    const moves = convertToSan(props.fen, pvMatch.split(" ")); // Extract and split moves into an array

    // Make sure we're always from white's perspective
    if (props.fen.includes(" b ")) {
      cp = -cp;
    }

    return { cp, nps, depth, seldepth, moves };
  } catch (error) {
    // This will importantly catch the error convertToSan would throw if the moves are not valid for the current fen,
    // which is caused by a race condition where the evals come in from the engine while the fen on the board is changed
    // since we don't get the position from the engine, it's hard to avoid this, instead we catch the error and wait for new moves from the engine
    return null;
  }
}

function convertToSan(fen: string, moves: string[]): string[] {
  const chess = new Chess(fen); // Initialize Chess instance with the FEN
  const sanMoves: string[] = [];
  for (const move of moves) {
    const san = chess.move(move)?.san; // Make the move and get its SAN notation
    if (san) {
      sanMoves.push(san); // Add the SAN move to the array
    } else {
      console.error(`Invalid move: ${move}`);
      break; // Stop processing if a move is invalid
    }
  }

  return sanMoves;
}

function movesToLine(fen: string, moves: string[]): string {
  const isWhiteToMove = fen.includes(" w ");
  const moveNumber = parseInt(fen.split(" ")[5], 10); // The move number is the 6th part of the FEN

  let result = "";
  let currentMoveNumber = moveNumber;

  for (let i = 0; i < moves.length; i++) {
    if (i % 2 === 0) {
      // White's move
      if (isWhiteToMove || i > 0) {
        result += `${currentMoveNumber}. `;
      } else {
        result += `${currentMoveNumber}.. `;
      }
    }

    result += `${moves[i]} `;

    if (i % 2 === 1 || (!isWhiteToMove && i === 0)) {
      // Increment the move number after black's move or if black starts
      currentMoveNumber++;
    }
  }

  return result.trim();
}

/**
 * Send a command to the Stockfish engine
 */
function sendCommand(command: string): boolean {
  if (stockfish) {
    stockfish.postMessage(command);
    return true;
  } else {
    console.error("Stockfish worker is not initialized.");
    return false;
  }
}

/**
 * Wait until the Stockfish worker is ready
 */
function waitForStockfish(): Promise<void> {
  return new Promise((resolve) => {
    if (stockfishLoaded.value) {
      resolve(); // Already ready
    } else {
      const unwatch = watch(
        () => stockfishLoaded.value,
        (isReady) => {
          if (isReady) {
            resolve();
            unwatch(); // Stop watching once resolved
          }
        }
      );
    }
  });
}

function unloadStockfish() {
  if (stockfish) {
    engineOn.value = false;
    stockfish.terminate();
    stockfish = null;
    stockfishLoaded.value = false;
    currentEvals.value = [];
  }
}

onBeforeUnmount(() => {
  unloadStockfish();
});
</script>

<style scoped>
.eval-line {
  max-width: 24rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

:deep(.toggle) {
  --toggle-width: 3.5rem;
}

@media (max-width: 1200px) {
  .eval-line {
    max-width: 20rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
