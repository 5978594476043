const playstyles = ["Guardian", "Observer", "Mediator", "Hunter", "Savage"];
const playstyleTooltips = [
  "Guardians are the most defensive bots. They focus on protecting their pieces and safeguard their king.",
  "Observers has the most balanced playstyle. Waiting for the right opportunity without leaving themselves open for attack.",
  "Mediators are the most flexible playstyle. Adapting to their opponent's moves and finding the best counterplay.",
  "Hunters are the most tactical playstyle. Creating complications and finding the best moves in complex positions.",
  "Savages are the most aggressive playstyle. They attack their opponent's pieces and create threats.",
];

const playstyleBg = ["#cf4751", "#8353a0", "#467baf", "#94b366", "#f1be0f"];

function getImg(playstyle: string) {
  return `https://storage.googleapis.com/chessiverse1/images/elements/playstyle/${playstyle.toLowerCase()}.webp`;
}

export function personaCategories() {
  return playstyles.map((playstyle, index) => {
    return {
      id: index + 1,
      name: playstyle,
      tooltip: playstyleTooltips[index],
      bg: playstyleBg[index],
      img: getImg(playstyle),
    };
  });
}

export const personaValues = {
  Openings: {
    desc: "Represents the level of opening theory knowledge, ranging from minimal understanding to highly advanced theoretical preparation.",
    range: [
      {
        name: "Unprepared",
        desc: "Minimal opening knowledge, relies on general principles.",
      },
      { name: "Basic", desc: "Understands common openings but lacks depth." },
      {
        name: "Moderate",
        desc: "Decent grasp of opening theory, some specific preparation.",
      },
      {
        name: "Advanced",
        desc: "Strong understanding of popular lines, prepared for most responses.",
      },
      {
        name: "Theoretical",
        desc: "Highly prepared, follows advanced theory with deep knowledge.",
      },
    ],
  },
  Repertoire: {
    desc: "Describes the variety of openings the bot uses, ranging from safe and stable to highly aggressive and sharp.",
    range: [
      {
        name: "Solid",
        desc: "Consistently prefers safe, stable opening choices.",
      },
      {
        name: "Cautious",
        desc: "Generally leans towards solid openings, avoiding risk.",
      },
      {
        name: "Balanced",
        desc: "Mixes solid and sharp openings, adapts to the opponent.",
      },
      {
        name: "Aggressive",
        desc: "Often chooses sharp lines, willing to create complications.",
      },
      {
        name: "Sharp",
        desc: "Always opts for aggressive, dynamic openings to gain early advantage.",
      },
    ],
  },
  Blunders: {
    desc: "Indicates the likelihood of making mistakes during gameplay, ranging from very rare blunders to frequent mistakes under pressure.",
    range: [
      {
        name: "Very Rare",
        desc: "Hardly ever blunders, maintains exceptional concentration.",
      },
      {
        name: "Rare",
        desc: "Infrequent mistakes, typically plays accurately.",
      },
      {
        name: "Occasional",
        desc: "Makes some mistakes, especially in complex positions.",
      },
      {
        name: "Frequent",
        desc: "Prone to making frequent errors under pressure.",
      },
      {
        name: "Very Frequent",
        desc: "Blunders often, struggling with focus in difficult situations.",
      },
    ],
  },
  Endgames: {
    desc: "Reflects the bot's skill in endgame situations, from casual ability with missed key moves to highly precise and consistent play.",
    range: [
      {
        name: "Casual",
        desc: "Struggles with endgames, frequently misses key moves.",
      },
      {
        name: "Basic",
        desc: "Limited skill, converts easy endgames but struggles with complexity.",
      },
      {
        name: "Average",
        desc: "Handles endgames with reasonable accuracy, converts standard positions.",
      },
      {
        name: "Skilled",
        desc: "Strong endgame ability, effectively turns small advantages into wins.",
      },
      {
        name: "Precise",
        desc: "Highly accurate, excels in complex endgames, rarely makes mistakes.",
      },
    ],
  },
  Playstyle: {
    desc: "Defines the general approach of the bot, whether it prefers positional play or dynamic and aggressive tactics.",
    range: [
      {
        name: "Positional",
        desc: "Prefers slow, strategic buildup, aiming for long-term stability.",
      },
      {
        name: "Cautious Positional",
        desc: "Leans towards positional play with occasional tactical moves.",
      },
      {
        name: "Balanced",
        desc: "Adapts between positional and dynamic styles as the game requires.",
      },
      {
        name: "Dynamic",
        desc: "Frequently opts for tactical opportunities and aggressive play.",
      },
      {
        name: "Highly Dynamic",
        desc: "Focuses on sharp tactics and immediate activity, always looking for complications.",
      },
    ],
  },
  Temperament: {
    desc: "Describes the bot's tendency to take risks, ranging from calm and cautious to highly aggressive risk-taking.",
    range: [
      {
        name: "Calm",
        desc: "Prioritizes safety and avoids unnecessary risks.",
      },
      {
        name: "Cautious",
        desc: "Generally prefers safe play but takes occasional calculated risks.",
      },
      {
        name: "Mixed",
        desc: "Alternates between calm and aggressive play, adapts based on position.",
      },
      {
        name: "Risk-Taker",
        desc: "Willing to take risks for better positions, pushes boundaries.",
      },
      {
        name: "Aggressive",
        desc: "Actively seeks risks and challenges, pushes hard to gain an advantage.",
      },
    ],
  },
  Tenacity: {
    desc: "Represents the bot's mental toughness and resilience, ranging from fragile to highly resilient in difficult situations.",
    range: [
      {
        name: "Fragile",
        desc: "Often gives up in difficult positions, struggles with maintaining focus.",
      },
      {
        name: "Vulnerable",
        desc: "Can handle tough positions but sometimes loses composure.",
      },
      {
        name: "Average",
        desc: "Shows resilience but not consistently against intense pressure.",
      },
      {
        name: "Determined",
        desc: "Strong mental toughness, fights back in challenging situations.",
      },
      {
        name: "Resilient",
        desc: "Exceptionally determined, never gives up, and thrives under pressure.",
      },
    ],
  },
};

export function getRangeString(personaCategory: string, range: number) {
  return `${personaCategory} ${range}%`;
}
