import type { PracticePosition, TimeControl } from "@/types/apitypes";

export enum UserInput {
  Abort,
  Resign,
  StartCasual,
  Rematch,
  HistoryPrevious,
  HistoryStart,
  HistoryEnd,
  HistoryNext,
  HistoryGoto,
  PlayNext,
  PlayAnotherSimilarRatedOpponent,
  StartChallenge,
  StartDailyMatchup,
  StartDailyPosition,
  RetryChallenge,
  RetryCustomChallenge,
  RetryPractice,
  StartPuzzle,
  MorePuzzles,
  UsePuzzleHint,
  ViewPuzzle,
  SelectBotForChallenge,
  AlternativeNext,
  AlternativePrevious,
}

export enum ChallengeState {
  StartingIntro,
  StartingCasual,
  StartingDailyPosition,
  StartingChallenge,
  StartingCustomChallenge,
  StartingPractice,
  StartingDailyMatchup,
  StartingPuzzleSet, // Starting a puzzle set for the first time (no puzzles made yet)
  ContinuingPuzzleSet, // Starting a puzzle set that has some puzzle solved already
  ViewingPuzzle, // Starting the next puzzle in a puzzle set
  PlayingPuzzle, // Started and waiting for moves in a puzzle set
  FirstMoveRated,
  PlayingRated,
  FirstMoveCasual,
  PlayingCasual,
  FinishedCasualWin,
  FinishedCasualNotWin,
  FinishedRatedWin,
  FinishedRatedNotWin,
  FinishedChallengeDifficultyWin, // Challenge with set difficulties, e.g. weekly challenges (and also with challengeId)
  FinishedChallengeDifficultyNotWin,
  FinishedChallengeCustomWin, // Custom challenge, i.e. without a challengeId (and also without difficulties)
  FinishedChallengeCustomNotWin,
  FinishedPracticeWin, // Challenge with challengeId, but withou difficulties
  FinishedPracticeNotWin,
  FinishedDailyPositionWin,
  FinishedDailyPositionNotWin,
  FinishedDailyMatchupWin,
  FinishedDailyMatchupNotWin,
  FinishedPuzzleSet,
  Aborted,
  HandlingInput,
  AnalyzingGame,
}

export enum ImageType {
  BotProfile,
  BotBackground,
}

// Used for ProfileCard if no real Bot is available
export type BotMock = {
  botId: string;
  name: string;
  rating: number;
  countryCode: string;
  countryName: string;
  color?: string;
  liveDate?: Date;
  occupation?: string;
  playstyleCategory?: number;
};

export type NewsItemContent = {
  icon: string;
  title: string;
  summary: string;
  date: string;
};

export type PlayTypes =
  | "intro"
  | "casual"
  | "challenge"
  | "custom"
  | "puzzle"
  | "continue"
  | "practice"
  | "dailymatchup"
  | "dailyendgame"
  | "dailymaster";

export type Difficulty =
  | "beginner"
  | "novice"
  | "intermediate"
  | "skilled"
  | "advanced";

export type RelativeDifficulty =
  | "simple"
  | "easy"
  | "balanced"
  | "hard"
  | "intense";

export type PracticeCategory = {
  name: string;
  description: string;
  icon: string;
  difficulty: string;
  positions: PracticePosition[];
};

export type PracticeSection = {
  name: string;
  categories: PracticeCategory[];
};

// Preferred settings when playing games (not all apply to all types of games)
export type GameSettings = {
  timeControl: TimeControl | null;
  rated: "rated" | "unrated" | null;
  color: "random" | "white" | "black" | null;
};
