<template>
  <div
    style="
      display: flex;
      gap: 5rem;
      flex-direction: column;
      align-items: center;
    "
  >
    <div v-if="getPremiumType() == 'trial'" class="card" style="padding: 1rem">
      <div>
        {{ getTrialText() }}
      </div>
    </div>
    <div class="header">
      <div
        style="
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          gap: 1rem;
        "
      >
        <h1 style="font-size: 2.5rem">
          Unlock the
          <span style="color: var(--clr-accent)">full potential</span> of
          Chessiverse
        </h1>
        <span
          >Upgrade now to play smarter, learn faster, and enjoy more with
          Chessiverse Premium!</span
        >
        <a class="btn btn-warning" style="font-size: 1.3rem" href="#plans">
          <i class="fa-solid fa-crown" /> Get Premium
        </a>
      </div>
      <img
        src="https://storage.googleapis.com/chessiverse1/images/elements/chessiverse_premium.webp"
      />
    </div>

    <div class="feature-table">
      <div class="feature-column">
        <div style="font-size: 1.5rem">Features</div>
        <hr style="margin: -1rem 0" />
        <div>
          <i
            class="fa-solid fa-swords"
            style="margin-right: 0.2rem; color: var(--clr-accent)"
          />
          Games
        </div>
        <div>
          <i
            class="fa-solid fa-robot"
            style="margin-right: 0.2rem; color: var(--clr-accent)"
          />
          Bots
        </div>
        <div>
          <i
            class="fa-solid fa-dumbbell"
            style="margin-right: 0.2rem; color: var(--clr-accent)"
          />
          Practice
        </div>
        <div>
          <i
            class="fa-solid fa-puzzle-piece"
            style="margin-right: 0.2rem; color: var(--clr-accent)"
          />
          Puzzles
        </div>
        <div>
          <i
            class="fa-solid fa-trophy"
            style="margin-right: 0.2rem; color: var(--clr-accent)"
          />
          Weekly challenges
        </div>
        <div>
          <i
            class="fa-solid fa-award"
            style="margin-right: 0.2rem; color: var(--clr-accent)"
          />
          Daily challenges
        </div>
        <div>
          <i
            class="fa-solid fa-sword"
            style="margin-right: 0.2rem; color: var(--clr-accent)"
          />
          Daily matchup
        </div>
        <div>
          <i
            class="fa-solid fa-arrow-trend-up"
            style="margin-right: 0.2rem; color: var(--clr-accent)"
          />
          Rated games
        </div>
        <div>
          <i
            class="fa-solid fa-chess-clock-flip"
            style="margin-right: 0.2rem; color: var(--clr-accent)"
          />
          Time controls
        </div>
      </div>
      <div class="card free-column">
        <div style="font-size: 1.5rem">Free</div>
        <hr style="margin: -1rem 0" />
        <div>Unlimited</div>
        <div>Free Tier Bots</div>
        <div>Free Tier Bots</div>
        <div>Free Tier Bots</div>
        <div>Yes</div>
        <div>No</div>
        <div>Yes</div>
        <div>
          <span>Casual or </span>
          <span style="white-space: nowrap">rated games</span>
        </div>
        <div>All time controls</div>
      </div>
      <div class="card premium-column">
        <div style="font-size: 1.5rem; color: white">
          <i class="fa-solid fa-crown" />
          <span style="white-space: nowrap"> Premium</span>
        </div>
        <hr style="margin: -1rem 0" />
        <div>Unlimited</div>
        <div>All 500+ Bots</div>
        <div>All 500+ Bots</div>
        <div>All 500+ Bots</div>
        <div>Yes</div>
        <div>Yes</div>
        <div>Yes</div>
        <div>
          <span>Casual or </span>
          <span style="white-space: nowrap">rated games</span>
        </div>
        <div>All time controls</div>
      </div>
    </div>
    <div
      style="
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
      "
      id="plans"
    >
      <h2>
        Choose your <span style="color: var(--clr-accent)">Premium</span> plan
      </h2>
      <div v-if="getPremiumType() == 'trial'">
        {{ getTrialText() }}
      </div>
      <PremiumBoxes />
    </div>
    <div
      style="
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 32rem;
        margin: auto;
      "
    >
      <PremiumFAQ />
    </div>
  </div>
</template>

<script setup lang="ts">
import PremiumFAQ from "@/components/premium/PremiumFAQ.vue";
import { useUserStore } from "@/stores/userStore";

import { getPremiumType } from "@/util/premium";
import PremiumBoxes from "@/components/premium/PremiumBoxes.vue";

function getTrialText() {
  let trial = useUserStore().user.data?.trial;
  if (trial == null) {
    return "";
  }

  const fullDaysLeft = Math.floor((trial - Date.now()) / 1000 / 60 / 60 / 24);

  if (fullDaysLeft <= 0) {
    return "You're on your last day of premium. Sign up for a plan so you don't miss out when it ends";
  } else if (fullDaysLeft == 1) {
    return "You still have free premium for one more day. Sign up for a plan so you don't miss out when it ends";
  } else {
    return `You still have free premium for ${fullDaysLeft} more days, sign up for a plan so you don't miss out when it ends`;
  }
}
</script>

<style scoped>
sup {
  top: -0.25rem;
}

.header {
  display: flex;
  max-width: 50rem;
  gap: 2rem;
}

.header > img {
  width: 30rem;
  height: 15rem;
}

.plan-card {
  width: 16rem;
  height: 16rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;
}

.plan-card button {
  align-self: flex-start;
  font-size: 1.3rem;
}

.plan-card span:nth-child(1) {
  color: var(--clr-main-lighter);
}

.plan-card span:nth-child(3) {
  color: var(--clr-main-lighter);
  margin-top: 1rem;
}

.plan-card span:nth-child(3) > span,
.plan-card span:nth-child(1) {
  font-size: 1.5rem;
  font-weight: 800;
  color: var(--clr-accent);
}

.plan-cards {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.most-popular {
  text-align: center;
  width: 7rem;
  background: var(--clr-accent);
  color: white;
  position: absolute;
  top: -1rem;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.feature-column {
  display: flex;
  gap: 2rem;
  flex-direction: column;
  padding: 1rem;
}

.premium-column {
  background-color: var(--clr-accent);
  display: flex;
  gap: 2rem;
  flex-direction: column;
  padding: 1rem;
  font-weight: 800;
}

.free-column {
  display: flex;
  gap: 2rem;
  flex-direction: column;
  padding: 1rem;
}

.feature-table {
  display: flex;
  gap: 1rem;
}

.feature-table .feature-column > div,
.feature-table .free-column > div,
.feature-table .premium-column > div {
  height: 2rem;
}

@media (max-width: 768px) {
  .plan-cards {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }

  .feature-table {
    gap: 0.5rem;
  }

  .header {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }

  .header > img {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 500px) {
  .feature-table .feature-column > div:nth-child(1),
  .feature-table .free-column > div:nth-child(1),
  .feature-table .premium-column > div:nth-child(1) {
    height: 5rem;
  }
}
</style>
