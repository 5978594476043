<template>
  <div
    class="card currentline-container"
    :class="{ muted: cs.manualViewing }"
    :style="{ backgroundColor: backgroundColor }"
    ref="container"
  >
    <span
      v-if="combinedLines().length === 0"
      style="color: var(--clr-nag-common)"
      >1.
    </span>
    <template v-for="(move, index) in combinedLines()" :key="index">
      <!-- Add move number before white moves -->
      <span v-if="index % 2 === 0" class="move-number">
        {{ Math.floor(index / 2) + 1 }}.
      </span>
      <TreeMove
        :uid="move.uid"
        :san="move.san"
        :nags="move.nags"
        :has-arrow="hasTag(move.comments)"
        :muted="move.muted"
        :navigableName="'current-line-move'"
        :class="{ selected: move.uid === cs.selectedNode?.uid }"
        :ref="
  (el: any) => {
    if (el) {
      moves[index] = (el as ComponentPublicInstance).$el || el;
    }
  }
"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import { type ComponentPublicInstance, nextTick, ref, watch } from "vue";
import { useCourseStore } from "@/stores/courseStore";
import { useModalsStore } from "@/stores/modalsStore";
import TreeMove from "@/components/common/tree/TreeMove.vue";
import { getCurrentSanLineFromUidPath, hasTag } from "@/util/course";
import { usePageStore } from "@/stores/pageStore";

const cs = useCourseStore();
const ms = useModalsStore();

interface Move {
  san: string;
  uid: number;
  nags: number[];
  muted?: boolean;
  comments: string[];
}

let currentSanLine = ref<Move[]>([]);
let partialSanLine = ref<Move[]>([]);
let backgroundColor = ref<string>("#fff");

const container = ref<HTMLElement | null>(null);
const moves = ref<HTMLElement[]>([]);

watch(
  () => cs.selectedNode,
  () => {
    const newSanLine = getCurrentSanLineFromUidPath(
      cs.findNodePath(cs.selectedNode?.uid)
    );

    // Create the full current line by concatenating currentSanLine and partialSanLine
    const fullCurrentSanLine = [
      ...currentSanLine.value,
      ...partialSanLine.value,
    ];

    // Check if newSanLine matches the start of fullCurrentSanLine
    if (
      newSanLine.length < fullCurrentSanLine.length &&
      newSanLine.every(
        (item, index) => item.san === fullCurrentSanLine[index].san
      )
    ) {
      // Move the remaining part of fullCurrentSanLine to partialSanLine
      partialSanLine.value = fullCurrentSanLine.slice(newSanLine.length);
      currentSanLine.value = newSanLine;
    } else {
      // Replace currentSanLine and reset partialSanLine
      currentSanLine.value = newSanLine;
      partialSanLine.value = [];
    }

    cs.refreshAlternatives(partialSanLine.value[0]?.uid);

    if (cs.selectedNode?.type.includes("alternative")) {
      setBackgroundColor("alternative");
    } else if (cs.selectedNode?.type.includes("dubious")) {
      setBackgroundColor("dubious");
    } else {
      // Set the board to the default color
      setBackgroundColor();
    }

    nextTick(() => {
      let scrollToIndex = currentSanLine.value.length - 1;
      if (scrollToIndex < 0) {
        scrollToIndex = 0;
      } else if (scrollToIndex > moves.value.length - 1) {
        scrollToIndex = moves.value.length - 1;
      }

      if (moves.value != null && moves.value.length > 0) {
        moves.value[scrollToIndex].scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
    });
  },
  { immediate: true }
);

function setBackgroundColor(lineType: string = "") {
  let color;
  if (cs != null && cs.manualViewing) {
    color = "var(--clr-main-lighter)";
  } else if (lineType === "alternative") {
    color = "var(--clr-linebg-alternative)";
  } else if (lineType === "dubious") {
    color = "var(--clr-linebg-caution)";
  } else {
    color = "#fff";
  }

  backgroundColor.value = color;
  return color;
}

function combinedLines(): Move[] {
  return [
    ...currentSanLine.value,
    ...partialSanLine.value.map((item) => ({ ...item, muted: true })),
  ];
}
</script>

<style scoped>
.currentline-container {
  padding: 0 0.5rem;
  display: flex;
  flex-wrap: nowrap;
  gap: 0.1rem;
  flex-direction: row;
  align-items: center;
  max-width: 21rem;
  min-width: 21rem;
  height: 2rem;
  overflow: hidden; /* Prevent scrolling */
  white-space: nowrap; /* Prevent line breaks */
  position: relative; /* Necessary for child elements */
}

.move-number {
  color: var(--clr-nag-common);
}

.move-san {
  display: flex;
  gap: 0.1rem;
  color: #000;
  border-radius: 4px;
  border: 2px solid #fff; /* Highlight the selected move */
}

.move-san:hover {
  cursor: pointer;
  text-decoration: underline;
  border: 2px solid var(--clr-main-lighter); /* Highlight on hover */
}

.move-san.selected {
  border: 2px solid #007bff; /* Highlight the selected move */
}

.move-san.chosenAlternative {
  border: 2px solid var(--clr-main-lighter); /* Highlight the chosen alternative */
}

.muted {
  opacity: 0.5;
}

@media (max-width: 1200px) {
  .currentline-container {
    max-width: 20rem;
    min-width: 20rem;
  }
}
</style>
