import { defineStore } from "pinia";
import { makeSanAndPlay, parseSan } from "chessops/san";
import { makeFen } from "chessops/fen";
import { markRaw, ref } from "vue";
import {
  type Game,
  parsePgn,
  type PgnNodeData,
  startingPosition,
  transform,
} from "chessops/pgn";
import { UserInput } from "@/types/internaltypes";
import { makeUci } from "chessops";
import * as api from "@/services/rest";
import { useUserStore } from "@/stores/userStore";
import { render } from "@/util/courseParser";
import { debounce } from "@/util/util";
import type { CoursePublicInfo, GetCourseInfo } from "@/types/apitypes";

export const useCourseStore = defineStore("course", {
  state: () => ({
    courseInfo: null as CoursePublicInfo | null,

    // Currently loaded course element (can be main course or a specific (1) model game
    courseTree: null as any | null,
    courseQueue: [] as any[],
    uidToNode: {} as Record<number, any>,

    // Current state of the loaded course data
    gameHeaderData: null as Map<string, string> | null,
    selectedNode: null as any | null,
    alternatives: [] as any[],
    selectedAlternative: null as any | null,
    choseAlternative: null as any | null,
    manualViewing: false,
    orientation: "black" as "white" | "black", // Default orientation for the course
  }),
  getters: {
    getNodeByUid: (state) => {
      return (uid: number) => state.uidToNode[uid] || null;
    },
    getCourseQueue(state) {
      return state.courseQueue;
    },
    getCourseTree(state) {
      return state.courseTree;
    },
  },
  actions: {
    async loadCourseInfo(courseId: string): Promise<CoursePublicInfo> {
      this.courseInfo = (await api.getCourseInfo(courseId)).data;

      return this.courseInfo;
    },
    async loadCourse(
      courseId: string,
      type: "main" | "model",
      gameid: string | null = null
    ) {
      const games = parsePgn(
        await this.loadCourseFromServer(courseId, type, gameid)
      );

      if (type == "model" && gameid != null) {
        this.gameHeaderData = games[0].headers;
      }

      await this.parseCourse(games[0]);
    },
    async loadCourseFromServer(
      courseId: string,
      type: "main" | "model",
      gameid: string | null = null
    ): Promise<string> {
      const pgnData = (await api.getCourseData(courseId, type, gameid)).data;
      const keyBuffer = new TextEncoder().encode(
        useUserStore().user.data?.id + "_" + courseId
      );
      const contentBuffer = Uint8Array.from(atob(pgnData), (c) =>
        c.charCodeAt(0)
      );

      const decodedBuffer = contentBuffer.map(
        (byte, index) => byte ^ keyBuffer[index % keyBuffer.length]
      );

      return new TextDecoder().decode(decodedBuffer);
    },
    async parseCourse(game: Game<PgnNodeData>) {
      this.courseTree = null;
      this.uidToNode = markRaw({});
      this.selectedNode = null;

      const pos = startingPosition(game.headers).unwrap();
      let id = 0;
      this.courseTree = markRaw(
        transform(game.moves, pos, (pos, node) => {
          const move = parseSan(pos, node.san);
          if (!move) {
            return;
          }

          const san = makeSanAndPlay(pos, move); // Mutating pos!
          const fen = makeFen(pos.toSetup());
          const uci = makeUci(move);

          // Clean line breaks in startingComments and comments
          const cleanedStartingComments = node.startingComments?.map(
            (comment) => comment.replace(/[\r\n]+/g, " ")
          );
          const cleanedComments = node.comments?.map((comment) =>
            comment.replace(/[\r\n]+/g, " ")
          );

          const decoratedNode = {
            ...node, // Keep comments and annotation glyphs
            startingComments: cleanedStartingComments, // Update cleaned startingComments
            comments: cleanedComments, // Update cleaned comments
            san, // Normalized SAN
            uci, // UCI
            fen, // Add arbitrary user data to node
            uid: id++,
            type: [],
          };

          return decoratedNode;
        })
      );

      const flattenToMap = (
        node: any,
        parent: number | null,
        depth: number,
        foldParents: number[]
      ) => {
        let foldable =
          depth >= 5 && (depth - 5) % 2 === 0 && node.children.length > 0;

        if (foldable && node.data != null && node.data.type.includes("main")) {
          foldable = false;
        }

        if (
          !foldable &&
          parent != null &&
          this.getNodeByUid(parent)?.data?.type.includes("main")
        ) {
          foldable = true;
        }

        if (node.data != null) {
          // Check for nodes that have more than one from from the course orientation perspective (a black opening repertoire having multiple black move options)
          if (
            node.data.type.some(
              (t: string) => t == "dubious" || t == "alternative"
            )
          ) {
            node.children.forEach((child: any) => {
              child.data.type = [...node.data.type];
            });
          } else if (
            depth % 2 == (this.orientation == "white" ? 0 : 1) &&
            node.children?.length > 1
          ) {
            for (let i = 1; i < node.children.length; i++) {
              const child = node.children[i];
              if (child.data != null) {
                if (
                  child.data.nags &&
                  child.data.nags.some((nag: number) =>
                    [2, 4, 5, 6].includes(nag)
                  )
                ) {
                  child.data.type.push("dubious");
                } else {
                  child.data.type.push("alternative");
                }
              }
            }
          }

          this.uidToNode[node.data.uid] = {
            ...node.data,
            children: node.children.map((child: any) => child.data.uid),
            parent: parent,
            foldable: foldable,
            foldParents: foldParents,
          };
        }
        node.children.forEach((child: any) =>
          flattenToMap(child, node.data ? node.data.uid : parent, depth + 1, [
            ...foldParents,
            ...(foldable ? [node.data?.uid] : []),
          ])
        );
      };

      const markMainLine = (node: any) => {
        if (node) {
          if (node.data) {
            // Mark the current node as part of the main line
            node.data.type.push("main");
          }

          // If there's a first child, continue marking the main line
          if (node.children.length > 0) {
            markMainLine(node.children[0]);
          }
        }
      };

      if (this.getCourseTree) {
        markMainLine(this.getCourseTree);
        flattenToMap(this.getCourseTree, null, 0, []);
        this.courseQueue = markRaw(render(this.getCourseTree));
      }

      this.selectMove(null);
    },
    selectMove(uid: number | null) {
      const debouncedNodeChanged = debounce(() => {
        this.manualViewing = false;
        if (uid == null) {
          this.selectedNode = null;
          this.updateAlternatives(null);
        } else {
          this.selectedNode = this.getNodeByUid(uid);
          this.updateAlternatives(this.selectedNode.uid);
        }
      }, 50);

      debouncedNodeChanged();
    },
    refreshAlternatives(preferredAlternativeUid: number | null) {
      this.updateAlternatives(this.selectedNode?.uid, preferredAlternativeUid);
    },
    updateAlternatives(
      nodeUid: number | null,
      preferredUid: number | null = null
    ) {
      this.choseAlternative = null;
      if (nodeUid == null) {
        // nodeUid==null means that we're at the root with no move selected already. So we need to return all
        // root nodes as alternatives. It's really unnecessary to do this dynamically, we could do it once in parseCourse
        // but as long as it's not a performance bottleneck, it's fine.

        const rootNodes = Object.keys(this.uidToNode)
          .map((key) => this.getNodeByUid(Number(key))) // Convert key to a number if keys are numbers
          .filter((node) => node.parent === null);

        this.alternatives = rootNodes.map((node) => ({
          ...node,
          selected: false,
        }));
        if (preferredUid == null) {
          this.alternatives[0].selected = true;
        } else {
          const alternative = this.alternatives.find(
            (a) => a.uid == preferredUid
          );
          if (alternative == undefined) {
            this.alternatives[0].selected = true;
          } else {
            alternative.selected = true;
          }
        }
        return;
      }

      const node = this.getNodeByUid(nodeUid);
      if (node.children.length > 0) {
        this.alternatives = node.children.map((child: number) => {
          return { ...this.getNodeByUid(child), selected: false };
        });

        if (preferredUid == null) {
          this.alternatives[0].selected = true;
        } else {
          const alternative = this.alternatives.find(
            (a) => a.uid == preferredUid
          );
          if (alternative == undefined) {
            this.alternatives[0].selected = true;
          } else {
            alternative.selected = true;
          }
        }
      } else {
        this.alternatives = [];
      }
    },
    findNodePath(uid: number | null): number[] | null {
      if (uid == null) {
        return null;
      }

      let current = this.getNodeByUid(uid);

      if (current == null) {
        return null;
      }

      const path = [uid];

      while (current.parent != null) {
        current = this.getNodeByUid(current.parent);
        path.unshift(current.uid);
      }

      return path;
    },
    navigate(input: UserInput, moveUid: number | null = null) {
      if (input == UserInput.HistoryNext) {
        if (moveUid != null) {
          this.selectMove(moveUid);
        } else if (this.selectedNode == null) {
          this.selectMove(0);
        } else if (this.alternatives.length > 0) {
          this.selectMove(this.alternatives.find((a) => a.selected)!.uid);
        }
      } else if (input == UserInput.HistoryPrevious) {
        this.selectMove(
          this.selectedNode.parent == null ? null : this.selectedNode.parent
        );
      } else if (input == UserInput.AlternativeNext) {
        const currentIndex = this.alternatives.findIndex(
          (item) => item.selected
        );
        const nextIndex =
          currentIndex + 1 > this.alternatives.length - 1
            ? 0
            : currentIndex + 1;
        this.alternatives[currentIndex].selected = false;
        this.alternatives[nextIndex].selected = true;
        this.choseAlternative = this.alternatives[nextIndex];
      } else if (input == UserInput.AlternativePrevious) {
        const currentIndex = this.alternatives.findIndex(
          (item) => item.selected
        );
        const nextIndex =
          currentIndex - 1 < 0
            ? this.alternatives.length - 1
            : currentIndex - 1;
        this.alternatives[currentIndex].selected = false;
        this.alternatives[nextIndex].selected = true;
        this.choseAlternative = this.alternatives[nextIndex];
      }
    },
  },
});
