<template>
  <div
    style="
      width: 10rem;
      display: flex;
      flex-direction: column;
      align-items: center;
    "
    v-tippy="{
      content: tooltip,
    }"
    class="half-donut-container"
  >
    <Doughnut
      :key="valueMain + '' + valueOff"
      :data="data"
      :options="options"
    />
    <span style="font-size: 1.5rem; margin-top: -2rem">{{
      valueDisplay ? valueDisplay : valueMain
    }}</span>
    <div style="display: flex; width: 80%">
      <span v-if="fromText" style="font-size: 0.8rem">{{ fromText }}</span>
      <span style="flex-grow: 1; padding: 0 0.5rem">{{ label }}</span>
      <span v-if="toText" style="font-size: 0.8rem">{{ toText }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  Chart as ChartJS,
  ArcElement,
  Legend,
  type ChartOptions,
} from "chart.js";
import { Doughnut } from "vue-chartjs";
import { ref, watch } from "vue";

const props = defineProps({
  label: { type: String, required: true },
  color: { type: String, default: "#41B883" },
  fromText: { type: String },
  toText: { type: String },
  valueDisplay: { type: String },
  valueMain: { type: Number, required: true },
  valueOff: { type: Number, required: true },
  tooltip: { type: String, required: true },
  labelCenter: { type: Boolean, default: false },
});

ChartJS.register(ArcElement, Legend);

let data = ref({
  labels: ["Opening quality"],
  datasets: [
    {
      backgroundColor: [props.color, "#ddd"],
      data: [props.valueMain, props.valueOff],
      borderWidth: 0,
    },
  ],
});

let options = ref<ChartOptions<"doughnut">>({
  responsive: true,
  maintainAspectRatio: true,
  aspectRatio: 2,
  rotation: -105,
  circumference: 210,
  cutout: "50%",
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false, // Disable tooltips
    },
  },
  hover: {
    mode: undefined, // Disable hover interactions
  },
});

// Watch the props and update the data object when props change
watch(
  () => [props.valueMain, props.valueOff],
  ([newValueMain, newValueOff]) => {
    data.value.datasets[0].data = [newValueMain, newValueOff];
  }
);
</script>

<style scoped>
.half-donut-container canvas {
  height: 80px !important;
}
</style>
