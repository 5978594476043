<template>
  <div v-if="loadingNextOpponent || !nextOpponentSuggestion"></div>
  <div
    v-else
    style="display: flex; flex-direction: column; align-items: center"
  >
    <div v-if="!isMobileWidth()" style="font-size: 1.3rem">
      {{ resultText() }}
    </div>
    <div
      style="font-size: 1rem"
      v-if="nextOpponentSuggestion!.lastGame.rated && nextOpponentSuggestion!.lastGame.termination != GameTermination.Aborted"
      v-html="getRatingChange(nextOpponentSuggestion!.lastGame.ratingChange!.new!.rating - nextOpponentSuggestion!.lastGame.ratingChange!.old.rating)"
    ></div>
    <div
      v-if="!gaveGameResignationFeedback && isBotResigned()"
      class="card"
      style="
        margin: 1rem 0;
        padding: 0.5rem;
        filter: drop-shadow(0.2rem 0.2rem 0.2rem #797979);
      "
    >
      <div
        style="
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 0.5rem;
        "
      >
        <span class="blue-text">Do you think the bot's resignation was:</span>
        <div style="display: flex">
          <a
            type="button"
            class="btn btn-info ph-no-capture"
            style="white-space: nowrap"
            @click="
              () => {
                resignFeedback(FeedbackType.Happy);
                track('start_game_box', 'resign_feedback', 'click', {
                  rating: 'happy',
                });
              }
            "
          >
            Too early
          </a>
          <a
            type="button"
            class="btn btn-info mx-2 ph-no-capture"
            style="white-space: nowrap"
            @click="
              () => {
                resignFeedback(FeedbackType.Neutral);
                track('start_game_box', 'resign_feedback', 'click', {
                  rating: 'neutral',
                });
              }
            "
          >
            Just right
          </a>
          <a
            type="button"
            class="btn btn-info ph-no-capture"
            style="white-space: nowrap"
            @click="
              () => {
                resignFeedback(FeedbackType.Sad);
                track('start_game_box', 'resign_feedback', 'click', {
                  rating: 'sad',
                });
              }
            "
          >
            Too late
          </a>
        </div>
        <a
          class="ph-no-capture"
          style="white-space: nowrap"
          role="button"
          @click="
            () => {
              resignFeedbackDisable();
              track('start_game_box', 'resign_feedback_dont_ask', 'click');
            }
          "
        >
          Don't ask again please
        </a>
      </div>
    </div>

    <div
      v-if="getPremiumState()"
      style="
        margin: 1rem 0 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
      "
    >
      <span>Suggested next opponent</span>
      <ProfileCircleWithPlaque
        :bot="nextOpponentSuggestion!.bots.suggested"
        style="
          filter: drop-shadow(0.2rem 0.2rem 0.2rem #797979);
          margin-bottom: 1.5rem;
        "
      />
      <button
        class="btn btn-info"
        style="padding: 0.2rem 0.5rem; font-size: 1.2rem"
        @click="
              () => {
                startGame(nextOpponentSuggestion!.bots.suggested.id);
                track('start_game_box', 'suggested', 'click');
              }"
      >
        <i class="fa-duotone fa-swords" /> Play now
      </button>
    </div>
    <div
      v-else
      style="
        display: flex;
        justify-content: center;
        text-align: center;
        gap: 1rem;
      "
    >
      <div
        style="
          margin: 1rem 0 1rem;
          display: flex;
          flex-direction: column;
          align-items: center;
        "
      >
        <span>Suggested</span>
        <ProfileCircleWithPlaque
          :bot="nextOpponentSuggestion!.bots.suggestedFree"
          style="
            filter: drop-shadow(0.2rem 0.2rem 0.2rem #797979);
            margin-bottom: 1.5rem;
          "
        />
        <button
          class="btn btn-info"
          style="padding: 0.2rem 0.5rem; font-size: 1.2rem"
          @click="
              () => {
                startGame(nextOpponentSuggestion!.bots.suggestedFree.id);
                track('start_game_box', 'suggested_free', 'click');
              }"
        >
          <i class="fa-duotone fa-swords" /> Play now
        </button>
      </div>
      <div
        style="
          margin: 1rem 0 1rem;
          display: flex;
          flex-direction: column;
          align-items: center;
        "
      >
        <span
          ><i
            class="fa-solid fa-crown"
            style="color: var(--clr-premium-yellow)"
          />
          Premium</span
        >
        <ProfileCircleWithPlaque
          :bot="nextOpponentSuggestion!.bots.suggested"
          :locked="true"
          style="margin-bottom: 1.5rem"
        />
        <button
          class="btn btn-warning"
          style="padding: 0.2rem 0.5rem; font-size: 1.2rem"
          @click="
            () => {
              router.push({
                name: 'premium',
              });
              track('start_game_box', 'suggested_locked', 'click');
            }
          "
        >
          <i class="fa-solid fa-crown" />
          Unlock
        </button>
      </div>
    </div>
    <div>
      <div style="display: flex; gap: 1rem">
        <div>
          <div
            v-if="!isMobileWidth()"
            class="bot-and-name"
            style="margin-bottom: 1.5rem"
          >
            <img
              v-tippy="{
                content: 'Random suitable bot around your rating',
              }"
              alt="Bot profile image"
              class="profile-image-circle"
              style="border: 2px solid #444"
              src="https://storage.googleapis.com/chessiverse1/images/elements/rated_box_124.webp"
            />
            <div
              class="card profile-name-text"
              style="
                background-color: var(--clr-accent);
                z-index: 10;
                white-space: nowrap;
              "
            >
              <span style="font-size: 0.9rem"
                ><i class="fa-solid fa-plus-minus" style="font-size: 0.8rem" />
                200</span
              >
            </div>
          </div>
          <button
            class="btn btn-info"
            style="padding: 0.2rem 0.5rem; font-size: 0.9rem"
            @click="
              () => {
                startGame(getPremiumState() ? nextOpponentSuggestion!.bots.random.id: nextOpponentSuggestion!.bots.randomFree.id);
                track('start_game_box', 'start_rated', 'click');
              }
            "
          >
            <i class="fa-solid fa-dice" style="font-size: 0.8rem" /> Random
          </button>
        </div>
        <div>
          <ProfileCircleWithPlaque
            v-if="!isMobileWidth()"
            :bot="nextOpponentSuggestion!.bots.rematch"
            :image-size="4"
            :font-size="'0.9rem'"
            style="margin-bottom: 1.5rem"
          />
          <button
            class="btn btn-info"
            style="padding: 0.2rem 0.5rem; font-size: 0.9rem"
            @click="
              () => {
                startGame(nextOpponentSuggestion!.bots.rematch.id);
                track('start_game_box', 'rematch', 'click');
              }
            "
          >
            <i class="fa-solid fa-arrow-rotate-right" /> Rematch
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import ProfileCircleWithPlaque from "@/components/common/ProfileCircleWithPlaque.vue";
import {
  FeedbackType,
  GameTermination,
  type NextOpponentSuggestion,
  Result,
  Side,
} from "@/types/apitypes";
import { useBotsStore } from "@/stores/botStore";
import { onMounted, ref } from "vue";
import { isMobileWidth } from "@/util/util";
import { track } from "@/util/tracking";
import { useUserStore } from "@/stores/userStore";
import { useRouter } from "vue-router";
import { getPremiumState } from "@/util/premium";
import { ChallengeState } from "@/types/internaltypes";
import { useToast } from "vue-toast-notification";
import { useGameStore } from "@/stores/gameStore";

const router = useRouter();
const us = useUserStore();
const bs = useBotsStore();

const props = defineProps({
  finishedGameId: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["userInput"]);

let loadingNextOpponent = ref(true);
let nextOpponentSuggestion = ref<NextOpponentSuggestion | null>();
let gaveGameResignationFeedback = ref(
  localStorage.getItem("disableResignFeedback") == "true"
);

function resignFeedbackDisable() {
  gaveGameResignationFeedback.value = true;
  localStorage.setItem("disableResignFeedback", "true");
}

function resultText() {
  if (!nextOpponentSuggestion.value) {
    return "";
  }

  if (nextOpponentSuggestion.value.lastGame.result == Result.Draw) {
    let drawTerminationText = "";
    switch (nextOpponentSuggestion.value.lastGame.termination) {
      case GameTermination.Threefold:
        drawTerminationText = "Game drawn by repetition. ";
        break;
      case GameTermination.Stalemate:
        drawTerminationText = "Game drawn by stalemate. ";
        break;
      case GameTermination.InsufficientMaterial:
        drawTerminationText = "Game drawn by material. ";
        break;
      case GameTermination.FiftyMove:
        drawTerminationText = "Game drawn by 50 moves rules. ";
        break;
      default: // Should never happen since the only draw conditions are above for now (when draw by agreement comes it needs to be added here)
        drawTerminationText = "";
    }

    return drawTerminationText + "Equally matched, well played.";
  } else if (
    (nextOpponentSuggestion.value.lastGame.userSide == Side.White &&
      nextOpponentSuggestion.value.lastGame.result == Result.White) ||
    (nextOpponentSuggestion.value.lastGame.userSide == Side.Black &&
      nextOpponentSuggestion.value.lastGame.result == Result.Black)
  ) {
    let beatenBot = useBotsStore().getBot(
      nextOpponentSuggestion.value.lastGame.bot.id
    );
    let winText = "";
    if (
      nextOpponentSuggestion.value?.lastGame.termination == GameTermination.Time
    ) {
      winText =
        beatenBot == null
          ? "You won on time."
          : beatenBot.short_name + " ran out of time.";
    } else if (
      nextOpponentSuggestion.value?.lastGame.termination ==
      GameTermination.Checkmate
    ) {
      winText = "Checkmate!";
    } else if (
      nextOpponentSuggestion.value?.lastGame.termination ==
      GameTermination.Resign
    ) {
      winText =
        beatenBot == null
          ? "You won by resignation."
          : beatenBot.short_name + " resigned.";
    }

    // Time, checkmate and resignation should be the only win conditions, but if if it was something else, winText will be empty and we just return "Great job!"

    return (winText + " Great job!").trim();
  } else if (
    nextOpponentSuggestion.value.lastGame.termination == GameTermination.Aborted
  ) {
    return "Game aborted, try again!";
  }

  // Only lost left as option

  let terminationText = "";
  if (
    nextOpponentSuggestion.value?.lastGame.termination == GameTermination.Time
  ) {
    terminationText = "Time's up! ";
  } else if (
    nextOpponentSuggestion.value?.lastGame.termination ==
    GameTermination.Checkmate
  ) {
    terminationText = "Checkmate! ";
  }

  return terminationText + "Better luck next time!";
}

function resignFeedback(feedback: FeedbackType) {
  if (props.finishedGameId == null) {
    console.error("No gameId while submitting feedback");
    return;
  }

  useGameStore()
    .sendFeedback(props.finishedGameId, { resignFeedback: feedback })
    .then(() => {
      useToast().success("Feedback sent. Thanks!");
      gaveGameResignationFeedback.value = true;
    })
    .catch((e: any) => {
      // No toast here since it really doesn't matter, we don't want the user to retry if it fails, and we don't to give wrong information, so just silence for now
      console.error("Failed sending feedback: " + e);

      // Too old JS version to have a finally block, so duplicating this here
      gaveGameResignationFeedback.value = true;
    });
}

// Not sure if this is a good way to generate elements, but it works. Maybe JSX would be better?
function getRatingChange(ratingChange: number) {
  ratingChange = Math.round(ratingChange);
  let color = ratingChange >= 0 ? "--clr-accent4" : "--clr-rect-3";
  let plusSign = ratingChange >= 0 ? "+" : "";

  let result = isMobileWidth() ? resultText() + " " : "";

  return `<span>${result}<span style="color: var(${color})">${plusSign}${ratingChange}</span> rating</span>`;
}

function startGame(botId: string) {
  window.location.href =
    window.location.href.split("?")[0] +
    "?bid=" +
    botId +
    "&type=casual&auto=true";
}

function isBotResigned() {
  let isResign =
    nextOpponentSuggestion.value?.lastGame.termination ==
    GameTermination.Resign;
  let botLost =
    (nextOpponentSuggestion.value?.lastGame.userSide == Side.White &&
      nextOpponentSuggestion.value?.lastGame.result == Result.White) ||
    (nextOpponentSuggestion.value?.lastGame.userSide == Side.Black &&
      nextOpponentSuggestion.value?.lastGame.result == Result.Black);

  return isResign && botLost;
}

onMounted(() => {
  loadingNextOpponent.value = true;
  useBotsStore()
    .getNextOpponent(props.finishedGameId)
    .then((n) => {
      nextOpponentSuggestion.value = n;
      loadingNextOpponent.value = false;
    });
});
</script>
<style scoped>
.profile-image-circle {
  width: 4rem;
  height: 4rem;
  z-index: 10;
  border-radius: 50%;
  border: 1.5px solid #444;
}

.profile-name-text {
  position: absolute;
  bottom: -1rem;
  padding: 0 0.5rem;
}

.bot-and-name {
  position: relative;
  display: flex;
  justify-content: center;
  margin: auto;
  z-index: 100;
}
</style>
