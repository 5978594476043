<template>
  <!--  <div class="loader"></div>-->
  <div :style="getStyle()" class="circle-loader"></div>
</template>

<script setup lang="ts">
const props = defineProps({
  size: {
    type: String,
    default: "3",
  },
  margins: {
    type: String,
    default: "0",
  },
  color: {
    type: String,
    default: "#dddddd",
  },
  colorDetail: {
    type: String,
    default: "var(--clr-accent)",
  },
});

function getStyle() {
  return {
    width: props.size + "rem",
    height: props.size + "rem",
    margin: props.margins,
    border: "3px solid " + props.color,
    borderLeftColor: props.colorDetail,
  };
}
</script>

<style scoped>
.circle-loader {
  margin-bottom: 3.5em;
  animation: loader-spin 1.2s infinite linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
