<template>
  <div />
</template>

<script setup lang="ts">
import { onMounted, type PropType } from "vue";
import { usePageStore } from "@/stores/pageStore";
import { GameTermination } from "@/types/apitypes";

const props = defineProps({
  termination: { type: Number as PropType<GameTermination> },
});

const emit = defineEmits(["isRunning"]);

function start() {
  emit("isRunning", { isRunning: true });
  usePageStore().setPlaySound(
    props.termination === GameTermination.Time ? "end-of-time" : "lose-sound-2"
  );
  setTimeout(() => {
    emit("isRunning", { isRunning: false });
  }, 1000);
}

onMounted(() => {
  start();
});
</script>

<style scoped>
@media (max-width: 359px) {
  .lose-text {
    font-size: 6rem;
  }
}
</style>
