<template>
  <div>
    <div class="contact-container">
      <h1>Contact Us</h1>
      <p>Have a question, feedback or need assistance?</p>

      <div class="contact-box">
        <h2>Feedback</h2>
        <p>
          For general feedback or bug reports you can use our
          <a
            href="#"
            class="ph-no-capture"
            @click="
              () => {
                ms.showFeedback();
                track('contact_us', 'feedback_modal_link', 'click');
              }
            "
            >quick feedback form</a
          >.
        </p>
      </div>

      <div class="contact-box">
        <h2>General Queries</h2>
        <p>For general inquiries or feedback, drop us an email:</p>
        <a href="mailto:info@chessiverse.com">info@chessiverse.com</a>
      </div>

      <div class="contact-box">
        <h2>Support</h2>
        <p>
          If you're experiencing issues or need technical assistance, reach out
          to our support team:
        </p>
        <a href="mailto:support@chessiverse.com">support@chessiverse.com</a>
      </div>

      <div class="contact-box">
        <h2>Join Our Discord Community</h2>
        <p>
          Connect with us and our community in real-time on our Discord server:
        </p>
        <a href="https://discord.gg/Js5uPNxEHj" target="_blank"
          >Click to Join Discord</a
        >
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import feedbackImageDarkGrey from "@/assets/images/feedback_darkgrey.svg";
import feedbackImageGrey from "@/assets/images/feedback_grey.svg";
import { ref } from "vue";
import { track } from "@/util/tracking.js";
import { useModalsStore } from "@/stores/modalsStore";

let ms = useModalsStore();

const feedbackImage = ref(feedbackImageGrey);
</script>

<style scoped>
.contact-container {
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

h1 {
  font-size: 2em;
  margin-bottom: 20px;
}

.contact-box {
  margin-top: 30px;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
}

h2 {
  font-size: 1.5em;
  margin-bottom: 15px;
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
</style>
