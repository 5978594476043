<template>
  <div
    class="card"
    style="
      width: 20rem;
      margin: 0 auto;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
    "
  >
    <h3 style="margin-bottom: 1rem">{{ getHeaderText() }}</h3>
    <div v-if="loginModalState == LoginModalState.Reset">
      <div style="width: 100%">
        <input
          type="text"
          placeholder="E-mail"
          class="form-control"
          id="emailInput"
          ref="emailInputReset"
          v-model="email"
          required
          @keydown.enter="resetPassword()"
        />
        <span style="color: red">{{ emailErrorText }}</span>
      </div>
    </div>
    <div
      v-if="loginModalState == LoginModalState.Login"
      style="display: flex; flex-direction: column"
    >
      <div
        style="
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          text-align: center;
        "
      >
        <SignInWithOauthButton
          text="Sign in with Google"
          logo="google"
          @sign-in="signInWithGoogle()"
          style="margin: auto; width: 12rem"
        />
        <SignInWithOauthButton
          text="Sign in with Facebook"
          logo="facebook"
          @sign-in="signInWithFacebook()"
          style="margin: auto; width: 12rem"
        />
        <span v-if="oauthErrorText != null" style="color: red">
          {{ oauthErrorText }}</span
        >
      </div>
      <hr class="hr" />
      <div
        style="
          background-color: white;
          margin: -2.5rem auto;
          text-align: center;
          width: 1.5rem;
          z-index: 10;
        "
      >
        <span>Or</span>
      </div>
      <div
        style="
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          margin-top: 3rem;
        "
      >
        <div style="width: 100%">
          <input
            type="text"
            placeholder="E-mail"
            class="form-control"
            id="emailInput"
            ref="emailInputLogin"
            v-model="email"
            required
            @keydown.enter="login()"
          />
          <span style="color: red">{{ emailErrorText }}</span>
        </div>
        <div style="position: relative; width: 100%">
          <input
            :type="showPassword ? 'text' : 'password'"
            placeholder="Password"
            class="form-control"
            id="passwordInput"
            v-model="password"
            required
            @keydown.enter="login()"
          />
          <i
            :class="
              'fa-solid position-in-input ' +
              (showPassword ? 'fa-eye-slash' : 'fa-eye')
            "
            @click="
              () => {
                showPassword = !showPassword;
                track('login_modal', 'show_login_password', 'click');
              }
            "
          ></i>
        </div>
        <span style="color: red; margin-top: -0.2rem; font-size: 0.8rem">{{
          passwordErrorText
        }}</span>
        <div style="text-align: center">
          <span
            style="margin-right: 1rem; font-size: 1rem; cursor: pointer"
            class="ph-no-capture"
            @click="
              () => {
                setType(LoginModalState.Reset);
                track('login_modal', 'reset_password_toggle', 'click');
              }
            "
          >
            Forgot password?</span
          >
          <button
            type="button"
            class="btn btn-info ph-no-capture"
            :disabled="loading"
            @click="login()"
            @keydown.enter="login(true)"
            style="height: 2.5rem; width: 5rem"
          >
            {{ loading ? "" : "Sign In" }}
            <Loader v-if="loading" size="1.5" />
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="loginModalState == LoginModalState.Signup"
      style="display: flex; flex-direction: column"
    >
      <div
        style="
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          text-align: center;
        "
      >
        <SignInWithOauthButton
          text="Sign up with Google"
          logo="google"
          @sign-in="signInWithGoogle()"
          style="margin: auto; width: 12rem"
        />
        <SignInWithOauthButton
          text="Sign up with Facebook"
          logo="facebook"
          @sign-in="signInWithFacebook()"
          style="margin: auto; width: 12rem"
        />

        <span v-if="oauthErrorText != null" style="color: red">
          {{ oauthErrorText }}</span
        >
      </div>
      <hr class="hr" />
      <div
        style="
          background-color: white;
          margin: -2.5rem auto;
          text-align: center;
          width: 1.5rem;
          z-index: 10;
        "
      >
        <span>Or</span>
      </div>
      <div style="display: flex; flex-direction: column; gap: 0.5rem">
        <div style="position: relative; width: 100%; margin-top: 3rem">
          <input
            type="text"
            placeholder="E-mail"
            class="form-control"
            id="emailInput"
            ref="emailInputSignup"
            v-model="email"
            required
            @keydown.enter="signup()"
          />
          <span style="color: red">{{ emailErrorText }}</span>
        </div>
        <div style="position: relative; width: 100%">
          <input
            :type="showPassword ? 'text' : 'password'"
            placeholder="Password"
            class="form-control"
            id="passwordInput"
            v-model="password"
            required
            @keydown.enter="signup()"
          />
          <i
            :class="
              'fa-solid position-in-input ' +
              (password ? 'extra-margin ' : '') +
              (showPassword ? 'fa-eye-slash' : 'fa-eye')
            "
            @click="
              () => {
                showPassword = !showPassword;
                track('login_modal', 'show_signup_password', 'click');
              }
            "
          ></i>
        </div>
        <div style="position: relative; width: 100%">
          <input
            :type="showPassword ? 'text' : 'password'"
            placeholder="Re-type password"
            class="form-control"
            id="passwordInputRetype"
            v-model="passwordRetype"
            required
            @keydown.enter="signup()"
          />
          <i
            :class="
              'fa-solid position-in-input ' +
              (passwordRetype ? 'extra-margin ' : '') +
              (showPassword ? 'fa-eye-slash' : 'fa-eye')
            "
            @click="
              () => {
                showPassword = !showPassword;
                track('login_modal', 'show_password', 'click');
              }
            "
          ></i>
        </div>
        <span style="color: red; margin-top: -0.2rem; font-size: 0.8rem">{{
          passwordErrorText
        }}</span>
        <div style="display: flex; gap: 0.5rem; align-self: end">
          <div
            v-if="showNewsletterConsent"
            class="ph-no-capture"
            style="display: flex; gap: 0.5rem"
            @click="
              () => {
                newsletterConsent = !newsletterConsent;
                track('login_modal', 'toggle_newsletter_consent', 'click', {
                  consent: newsletterConsent,
                });
              }
            "
            role="button"
          >
            <div style="width: 2rem; margin: -0.5rem 0 0 0.5rem">
              <input
                type="checkbox"
                id="newsletter-consent"
                name="newsletter-consent"
                value="newsletter-consent"
                v-model="newsletterConsent"
              />
            </div>
            <span style="font-size: 0.8rem"
              >Yes, sign me up to get occasional news and tips and tricks!</span
            >
          </div>
          <button
            type="button"
            class="btn btn-info ph-no-capture"
            :disabled="!passwordValid || !passwordRetypeValid || loading"
            @click="signup()"
            @keydown.enter="signup(true)"
            style="height: 2.5rem; width: 7rem"
          >
            {{ loading ? "" : "Sign Up" }}
            <Loader v-if="loading" size="1.5" />
          </button>
        </div>
        <div style="font-size: 0.8rem; text-align: center; margin-top: 1rem">
          By signing up you agree to our
          <a
            href="/tos"
            class="ph-no-capture"
            target="_blank"
            @click="track('login_modal', 'terms', 'click')"
            >Terms of Service</a
          >
        </div>
      </div>
    </div>
    <div
      v-if="loginModalState == LoginModalState.Reset"
      style="display: flex; gap: 1rem"
    >
      <button
        type="button"
        class="btn btn-outline-info ph-no-capture"
        :disabled="loading"
        @click="
          () => {
            loginModalState = LoginModalState.Login;
            track('login_modal', 'reset_password_button', 'click');
          }
        "
        @keydown.enter="
          () => {
            loginModalState = LoginModalState.Login;
            track('login_modal', 'reset_password_button', 'keydown_enter');
          }
        "
        style="height: 2.5rem; width: 8rem"
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-info ph-no-capture"
        :disabled="loading"
        @click="
          () => {
            resetPassword();
            track('login_modal', 'reset_password_button', 'click');
          }
        "
        @keydown.enter="
          () => {
            resetPassword();
            track('login_modal', 'reset_password_button', 'keydown_enter');
          }
        "
        style="height: 2.5rem; width: 8rem"
      >
        {{ loading ? "" : "Send reset email" }}
        <Loader v-if="loading" size="1.5" />
      </button>
    </div>
    <div
      v-if="loginModalState == LoginModalState.Login"
      style="margin-top: 1rem"
    >
      <span
        style="margin-right: 1rem; font-size: 1rem; cursor: pointer"
        class="ph-no-capture"
        @click="
          () => {
            toggleLoginSignup();
            track('login_modal', 'toggle_login_signup', 'click');
          }
        "
      >
        No account? Sign Up
        <i class="fa-solid fa-arrow-right" style="color: var(--clr-accent)"
      /></span>
    </div>
    <div
      v-if="loginModalState == LoginModalState.Signup"
      style="
        display: flex;
        gap: 0.5rem;
        flex-direction: column;
        align-items: center;
      "
    >
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 1rem;
        "
      >
        <span
          style="margin-right: 1rem; font-size: 1rem; cursor: pointer"
          class="ph-no-capture"
          @click="
            () => {
              toggleLoginSignup();
              track('login_modal', 'toggle_signup_login', 'click');
            }
          "
        >
          Have an account? Sign In
          <i class="fa-solid fa-arrow-right" style="color: var(--clr-accent)"
        /></span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, type Ref } from "vue";
import { useUserStore } from "@/stores/userStore";
import Loader from "@/components/util/Loader.vue";
import { useRoute, useRouter } from "vue-router";
import { track } from "@/util/tracking";
import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { auth } from "@/firebase";
import SignInWithOauthButton from "@/components/common/SignInWithOauthButton.vue";
import { useToast } from "vue-toast-notification";

enum LoginModalState {
  Login,
  Signup,
  Reset,
}

const router = useRouter();

let loginModalState = ref<LoginModalState>(getInitialState());

let showNewsletterConsent = ref(false); // Disable this for now since the oauth wasn't working with it
let newsletterConsent = ref<boolean>(
  showNewsletterConsent.value ? false : true
);
const loading = ref(false);
const emailInputLogin = ref(null);
const emailInputSignup = ref(null);
const emailInputReset = ref(null);
let showPassword = ref(false);
let oauthErrorText = ref<string | null>(null);

watch(emailInputLogin, (value) => {
  if (value != null) {
    // @ts-ignore
    emailInputLogin.value?.focus();
  }
});

watch(emailInputSignup, (value) => {
  if (value != null) {
    // @ts-ignore
    emailInputSignup.value?.focus();
  }
});

watch(emailInputReset, (value) => {
  if (value != null) {
    // @ts-ignore
    emailInputReset.value?.focus();
  }
});

function getInitialState(): LoginModalState {
  if (useRoute().meta?.type != null && useRoute().meta.type == "signup") {
    return LoginModalState.Signup;
  }

  return LoginModalState.Login;
}

const focusEmailInput = () => {
  document.getElementById("emailInput")!.focus();
};

const userStore = useUserStore();

watch(
  () => loginModalState,
  () => {
    focusEmailInput();
  }
);

let email: Ref<string> = ref("");
let password: Ref<string> = ref("");
let passwordRetype: Ref<string> = ref("");
let passwordValid: Ref<boolean> = ref(false);
let passwordRetypeValid: Ref<boolean> = ref(false);
let emailErrorText: Ref<string> = ref("");
let passwordErrorText: Ref<string> = ref("");
let fullErrorText: Ref<string> = ref("");
let badLoginText = "Wrong e-mail or password";

const resetValues = () => {
  loginModalState.value = LoginModalState.Login;
  email.value = "";
  password.value = "";
  passwordRetype.value = "";
  passwordValid.value = false;
  passwordRetypeValid.value = false;
  emailErrorText.value = "";
  passwordErrorText.value = "";
  fullErrorText.value = "";
  newsletterConsent.value = false;
};

watch(password, (value) => {
  if (passwordErrorText.value == badLoginText) {
    passwordErrorText.value = "";
  }

  if (value.length == 0 || loginModalState.value == LoginModalState.Login) {
    // Don't validate on login (or if the user hasn't started typing yet)
    document.getElementById("passwordInput")?.classList.remove("is-valid");
    document.getElementById("passwordInput")?.classList.remove("is-invalid");
    passwordValid.value = false;
    passwordErrorText.value = "";
  } else if (value.length >= 6) {
    document.getElementById("passwordInput")?.classList.add("is-valid");
    document.getElementById("passwordInput")?.classList.remove("is-invalid");
    passwordValid.value = true;
    passwordErrorText.value = "";
  } else {
    document.getElementById("passwordInput")?.classList.add("is-invalid");
    document.getElementById("passwordInput")?.classList.remove("is-valid");
    passwordValid.value = false;
    passwordErrorText.value = "Must be at least 6 characters long";
  }
});

watch(passwordRetype, (value) => {
  if (passwordErrorText.value == badLoginText) {
    passwordErrorText.value = "";
  }

  if (value.length == 0) {
    document
      .getElementById("passwordInputRetype")
      ?.classList.remove("is-valid");
    document
      .getElementById("passwordInputRetype")
      ?.classList.remove("is-invalid");
    passwordRetypeValid.value = false;
    passwordErrorText.value = "";
  } else if (value == password.value) {
    document.getElementById("passwordInputRetype")?.classList.add("is-valid");
    document
      .getElementById("passwordInputRetype")
      ?.classList.remove("is-invalid");
    passwordRetypeValid.value = true;
    passwordErrorText.value = "";
  } else {
    document.getElementById("passwordInputRetype")?.classList.add("is-invalid");
    document
      .getElementById("passwordInputRetype")
      ?.classList.remove("is-valid");
    passwordRetypeValid.value = false;
    passwordErrorText.value = "Passwords doesn't match";
  }
});

const resetPassword = () => {
  loading.value = true;

  userStore
    .resetPassword(email.value)
    .then(() => {
      useToast().success("Reset email sent, check you inbox!");
      loading.value = false;
      loginModalState.value = LoginModalState.Login;
    })
    .catch((error) => {
      console.error("Problem when resetting password", error);
      loading.value = false;
    });
};

const setType = (newType: LoginModalState) => {
  loginModalState.value = newType;
  focusEmailInput();
};

const doneWithLogin = (newUser: boolean) => {
  loading.value = false;

  if (newUser) {
    // New users go to the intro game
    localStorage.setItem("gamesettings_flash", "0");

    if (newsletterConsent.value) {
      userStore.setNewsletterConsentAccepted();
    }

    router.push({
      name: "dashboardsignedup",
    });
  } else {
    // Returning users just go to the dashboard
    router.push({
      name: "dashboard",
    });
  }
};

const login = (usedEnterKey: boolean = false) => {
  loading.value = true;

  userStore
    .signin(email.value, password.value)
    .then(() => {
      track(
        "login_modal",
        "login_success",
        usedEnterKey ? "keydown_enter" : "click"
      );
      doneWithLogin(false);
    })
    .catch((error) => {
      if (error.code == "auth/invalid-login-credentials") {
        track(
          "login_modal",
          "login_failure",
          usedEnterKey ? "keydown_enter" : "click",
          {
            error: "Invalid credentials",
          }
        );
        passwordErrorText.value = badLoginText;
        loading.value = false;
      } else {
        console.error("Unexpected error logging in", error);
        track(
          "login_modal",
          "login_failure",
          usedEnterKey ? "keydown_enter" : "click",
          {
            error: "Unexpected error: " + error,
          }
        );
        passwordErrorText.value = "Unexpected error, try again later";
      }
      loading.value = false;
    });
};

const toggleLoginSignup = () => {
  email.value = "";
  password.value = "";
  passwordRetype.value = "";
  loginModalState.value =
    loginModalState.value == LoginModalState.Login
      ? LoginModalState.Signup
      : LoginModalState.Login;
  focusEmailInput();
};

// Function to trigger Google sign-in
function signInWithGoogle() {
  signInWithPopup(auth, new GoogleAuthProvider())
    .then((credential) => {
      // The signed-in user info
      useUserStore()
        .finalizeSignin(credential)
        .then((isNew) => {
          doneWithLogin(isNew);
          track(
            "login_modal",
            isNew ? "signup_success_google" : "signin_success_google",
            "click"
          );
        });
    })
    .catch((error) => {
      if (error.code === "auth/account-exists-with-different-credential") {
        oauthErrorText.value =
          "Account already exists, try Facebook sign in, or email/password";
      } else {
        console.error("Error during Google sign-in:", error);
        oauthErrorText.value = "Error during sign-in, try another method";
      }
    });
}

// Function to trigger Facebook sign-in
function signInWithFacebook() {
  signInWithPopup(auth, new FacebookAuthProvider())
    .then((credential) => {
      // The signed-in user info
      useUserStore()
        .finalizeSignin(credential)
        .then((isNew) => {
          doneWithLogin(isNew);
          track(
            "login_modal",
            isNew ? "signup_success_facebook" : "signin_success_facebook",
            "click"
          );
        });
    })
    .catch((error) => {
      if (error.code === "auth/account-exists-with-different-credential") {
        oauthErrorText.value =
          "Account already exists, try Google sign in, or email/password";
      } else {
        console.error("Error during Facebook sign-in:", error);
        oauthErrorText.value = "Error during sign-in, try another method";
      }
    });
}

const signup = (usedEnterKey: boolean = false) => {
  if (!passwordValid.value || !passwordRetypeValid.value) {
    track(
      "login_modal",
      "signup_failure",
      usedEnterKey ? "keydown_enter" : "click",
      {
        error: "Invalid input",
        passwordValid: passwordValid.value,
        passwordRetypeValid: passwordRetypeValid.value,
      }
    );
    return;
  }

  loading.value = true;

  userStore
    .signup(email.value, password.value)
    .then(() => {
      doneWithLogin(true);
      track(
        "login_modal",
        "signup_success",
        usedEnterKey ? "keydown_enter" : "click"
      );
    })
    .catch((error) => {
      if (error.code == "auth/email-already-in-use") {
        passwordErrorText.value = "Account already exists, login instead";
        track(
          "login_modal",
          "signup_failure",
          usedEnterKey ? "keydown_enter" : "click",
          {
            error: "Account already exists",
          }
        );
      } else {
        console.error("Problem with signing up", error);
        track(
          "login_modal",
          "signup_failure",
          usedEnterKey ? "keydown_enter" : "click",
          {
            error: error,
          }
        );
      }
      loading.value = false;
    });
};

function getHeaderText() {
  if (loginModalState.value == LoginModalState.Login) {
    return "Sign In";
  } else if (loginModalState.value == LoginModalState.Signup) {
    return "Sign Up";
  } else if (loginModalState.value == LoginModalState.Reset) {
    return "Reset password";
  } else {
    return "";
  }
}
</script>

<style scoped>
.position-in-input {
  position: absolute;
  right: 0.75rem;
  top: 0.75rem;
  cursor: pointer;
  color: var(--clr-accent);
}

.extra-margin {
  right: 2rem;
}
</style>
