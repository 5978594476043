import firebase from "firebase/compat/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { useUserStore } from "@/stores/userStore";

const firebaseConfig = {
  apiKey: "AIzaSyAqbEV-j7edaiXHrFLyRnS3UgS1tN8TACI",
  authDomain: "chessiverse.com",
  projectId: "narochess",
  storageBucket: "narochess.appspot.com",
  messagingSenderId: "241305620106",
  appId: "1:241305620106:web:c9edf697d1cd87b5fb6773",
  measurementId: "G-22BDE8T00K",
};

const app = firebase.initializeApp(firebaseConfig);
getAnalytics(app);

export const auth = getAuth(app);

auth.onAuthStateChanged((user) => {
  if (user) {
    useUserStore().user.firebaseUser = user;
  } else {
    useUserStore().user.firebaseUser = null;
  }
});

export async function getUserIdToken(
  forceRefresh = false
): Promise<string | null> {
  return new Promise((resolve, reject) => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const idToken = await user.getIdToken(forceRefresh); // Get the ID token, force refresh if needed
          resolve(idToken);
        } catch (error) {
          reject(error);
        }
      } else {
        resolve(null); // No user is logged in
      }
    });
  });
}
