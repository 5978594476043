<template>
  <div class="opponentgrid">
    <div class="left">
      <MainPlayBox style="width: 100%" :bots="allBots" />
      <QuickPlay :showMore="true" class="main-gradient" />
    </div>
    <div v-if="isMobileWidth()" style="text-align: center">
      <hr />
      <h2>All bots</h2>
    </div>
    <div class="right">
      <div>
        <div class="pick-opponent-header">
          <span style="color: var(--clr-accent); font-size: 2rem"></span>
          <div
            style="
              display: flex;
              justify-content: space-between;
              gap: 0.5rem;
              align-items: center;
            "
          >
            <div class="filter-section">
              <Toggle
                v-if="!getPremiumState()"
                v-model="premiumToggle"
                class="filter-toggle ph-no-capture"
              >
                <template v-slot:label="{ checked, classList }">
                  <span v-if="checked" :class="classList.label"
                    ><i class="fa-solid fa-crown" /> Premium</span
                  >
                  <span v-else :class="classList.label"
                    ><i class="fa-solid fa-crown" /> Premium</span
                  >
                </template>
              </Toggle>
            </div>
            <div class="filter-section">
              <div style="position: relative">
                <button
                  class="btn btn-info dropdown-toggle ph-no-capture"
                  type="button"
                  @click="
                    () => {
                      toggleFilter();
                      track('opponent_list', 'toggle_filter', 'click', {
                        show_filter: showFilter,
                      });
                    }
                  "
                >
                  More filters
                </button>

                <div
                  v-if="!showFilter && !deepEqual(filter, defaultFilter)"
                  class="filterflag"
                >
                  <i class="fa-solid fa-flag"></i>
                </div>
              </div>

              <div class="dropdown">
                <button
                  class="btn btn-info dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Sort by: {{ sortType }}
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li>
                    <a
                      class="dropdown-item ph-no-capture"
                      @click="
                        () => {
                          sortType = 'Rating';
                          track('opponent_list', 'sort_type_rating', 'click');
                        }
                      "
                      >Rating</a
                    >
                  </li>
                  <li>
                    <a
                      class="dropdown-item ph-no-capture"
                      @click="
                        () => {
                          sortType = 'Name';
                          track('opponent_list', 'sort_type_name', 'click');
                        }
                      "
                      >Name</a
                    >
                  </li>

                  <li>
                    <a
                      class="dropdown-item ph-no-capture"
                      @click="
                        () => {
                          sortType = 'Playstyle';
                          track(
                            'opponent_list',
                            'sort_type_playstyle',
                            'click'
                          );
                        }
                      "
                      >Playstyle</a
                    >
                  </li>
                  <li v-if="!getPremiumState()">
                    <a
                      class="dropdown-item ph-no-capture"
                      @click="
                        () => {
                          sortType = 'Premium';
                          track('opponent_list', 'sort_type_premium', 'click');
                        }
                      "
                      >Premium</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div v-if="showFilter" class="mb-2 d-flex filter-align">
          <div class="card" style="width: 18rem; padding: 1rem">
            <div class="mb-1 d-flex">
              <label for="search" style="color: var(--clr-accent)"
                >Search:
                <input
                  type="text"
                  id="search"
                  name="search"
                  placeholder="Name, openings, country etc."
                  style="width: 16rem"
                  v-model="filter.search"
              /></label>
            </div>
            <div style="display: flex; gap: 0.5rem; margin-block: 1rem">
              <Toggle
                v-model="beatenToggle"
                class="filter-toggle ph-no-capture"
              >
                <template v-slot:label="{ checked, classList }">
                  <span v-if="checked" :class="classList.label">Beaten</span>
                  <span v-else :class="classList.label">Beaten</span>
                </template>
              </Toggle>
              <Toggle
                v-model="unbeatenToggle"
                class="filter-toggle ph-no-capture"
              >
                <template v-slot:label="{ checked, classList }">
                  <span v-if="checked" :class="classList.label">Unbeaten</span>
                  <span v-else :class="classList.label">Unbeaten</span>
                </template>
              </Toggle>
            </div>
            <div class="rating-and-slider">
              <span style="color: var(--clr-accent)">Rating:</span>
              <Slider
                :min="0"
                :max="2500"
                :lazy="true"
                :step="10"
                showTooltip="drag"
                class="rating-slider"
                v-model="filter.rating"
              />
            </div>

            <div>
              <span style="color: var(--clr-accent)">Playstyle:</span>

              <div style="display: flex; gap: 0.5rem">
                <div
                  v-for="(playstyle, i) in playstyles"
                  :key="'playstyle' + i"
                  v-tippy="{
                    content: isMobileWidth() ? '' : playstyle.tooltip,
                  }"
                >
                  <img
                    role="button"
                    :src="playstyle.img"
                    :style="{
                      height: '2rem',
                      width: '2rem',
                      opacity: filter.excludePlaystyles.includes(i) ? 0.2 : 1,
                    }"
                    @click="
                      () => {
                        if (filter.excludePlaystyles.includes(i)) {
                          filter.excludePlaystyles =
                            filter.excludePlaystyles.filter((e: any) => e !== i);
                        } else {
                          filter.excludePlaystyles.push(i);
                        }
                      }
                    "
                  />
                </div>
              </div>
            </div>
            <div style="display: flex; justify-content: end">
              <a
                type="button"
                class="btn btn-info ph-no-capture"
                style="padding: 0.2rem; height: 2rem"
                @click="
                  () => {
                    resetFilter();
                    track('opponent_list', 'reset_filter', 'click');
                  }
                "
                >Clear</a
              >
            </div>
          </div>
        </div>
        <div :style="{ textAlign: isMobileWidth() ? 'center' : 'right' }">
          Showing: {{ getShowingText() }}
        </div>
        <div
          v-if="!bs.fetched || filteredBots == null || loadingBots"
          class="bot-card-container pulsate-load-strong"
        >
          <div v-for="i in 40" :key="'filler' + i" class="bot-card">
            <div>
              <div
                class="card main-gradient"
                :style="{
                  width: getProfileCardScale().width + 'rem',
                  height: getProfileCardScale().width + 'rem',
                }"
              ></div>
              <div
                class="card"
                :style="{
                  marginTop: '0.2rem',
                  width: getProfileCardScale().width + 'rem',
                  height: getProfileCardScale().fontSize * 1.5 + 'rem',
                }"
              ></div>
            </div>
          </div>
        </div>
        <div v-else class="bot-card-container">
          <div
            v-for="(bot, index) in filteredBots"
            :key="index"
            class="bot-card"
          >
            <ProfileCard
              :profileScaleSet="getProfileCardScale()"
              :bot="bot"
              :fadeIntoViewport="true"
              :showPlaystyle="true"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useBotsStore } from "@/stores/botStore";
import { usePageStore } from "@/stores/pageStore";
import type { Bot } from "@/types/apitypes";
import { ref, watch, type Ref, nextTick, onMounted } from "vue";
import ProfileCard from "@/components/common/ProfileCard.vue";
import MainPlayBox from "@/components/play/MainPlayBox.vue";
import QuickPlay from "@/components/dashboard/QuickPlay.vue";
import Slider from "@vueform/slider";
import { deepEqual, isMobileWidth } from "@/util/util";
import { track } from "@/util/tracking";
import { ImageType } from "@/types/internaltypes";
import Toggle from "@vueform/toggle";
import { getPremiumState } from "@/util/premium";
import { personaCategories } from "@/util/personplaycategory";

const bs = useBotsStore();
const ps = usePageStore();

const playstyles = personaCategories();

const filterVersion = "3"; // Change this to invalidate the filter in local storage

const defaultFilter = {
  premium: true,
  beaten: true,
  unbeaten: true,
  search: "",
  rating: [0, 2500],
  version: filterVersion,
  excludePlaystyles: [] as number[],
};

// Using intermediate toggle booleans instead of the filter object directly since the toggle element animation bugged out (too many change on the page I guess)
let beatenToggle = ref(true);
let unbeatenToggle = ref(true);
let premiumToggle = ref(true);

let loadingBots = ref(true);
let filter = ref({ ...JSON.parse(JSON.stringify(defaultFilter)) });
let filteredBots: Ref<Bot[] | null> = ref(null);
let filteredBeaten = ref(0);
let allBots = ref();
let sortType = ref("Rating");
let showFilter = ref(false);
let debounce: any = null;

watch([beatenToggle, unbeatenToggle, premiumToggle], () => {
  setTimeout(() => {
    filter.value = {
      premium: premiumToggle.value,
      beaten: beatenToggle.value,
      unbeaten: unbeatenToggle.value,
      search: filter.value.search,
      rating: filter.value.rating,
      version: filterVersion,
      excludePlaystyles: filter.value.excludePlaystyles,
    };
  }, 500);
});

watch(sortType, () => {
  filteredBots.value = [];
  nextTick().then(() => {
    filteredBots.value = bs.list.slice(0);
    orderBots();
  });
});

watch(
  filter,
  () => {
    if (debounce != null) {
      clearTimeout(debounce);
    }
    debounce = setTimeout(() => {
      filteredBots.value = [];
      nextTick().then(() => {
        filteredBots.value = filterBots();
        orderBots();
        localStorage.setItem("botListFilter", JSON.stringify(filter.value));
      });
    }, 10);
  },
  { deep: true }
);

onMounted(() => {
  if (localStorage.getItem("botListFilter") != null) {
    let storedFilter = JSON.parse(localStorage.getItem("botListFilter")!);

    // Only load the filter if it matches the current version
    if (storedFilter.version === filterVersion) {
      filter.value = storedFilter;
      beatenToggle.value = filter.value.beaten;
      unbeatenToggle.value = filter.value.unbeaten;
      premiumToggle.value = filter.value.premium;
    }
  }

  bs.refresh().then(() => {
    allBots.value = bs.list.slice(0);
    filteredBots.value = bs.list.slice(0);
    orderBots();
    if (debounce != null) {
      clearTimeout(debounce);
    }
    debounce = setTimeout(() => {
      filteredBots.value = [];
      nextTick().then(() => {
        filteredBots.value = filterBots();
        orderBots();
        localStorage.setItem("botListFilter", JSON.stringify(filter.value));
        preloadImages(filteredBots);
        loadingBots.value = false;
      });
    }, 10);
  });
});

function preloadImages(bots: Ref<Bot[] | null>) {
  if (bots.value == null) {
    return;
  }
  bots.value.forEach((b) => {
    const imgBackground = new Image();
    const imgProfile = new Image();
    imgBackground.src = ps.img(b.id, ImageType.BotBackground, "20");
    imgProfile.src = ps.img(b.id, ImageType.BotProfile, "175");
  });
}

function getProfileCardScale() {
  if (window.innerWidth < 576) {
    // return { width: 8, height: 9.6, fontSize: 0.8 };
    return { width: 6.5, height: 7.8, fontSize: 0.8 };
  } else {
    return { width: 10, height: 12, fontSize: 1 };
  }
}

function resetFilter() {
  filter.value = { ...JSON.parse(JSON.stringify(defaultFilter)) };
}
function toggleFilter() {
  showFilter.value = !showFilter.value;
}

function filterBots() {
  let result = bs.list.slice(0).filter((b) => {
    if (
      filter.value.search &&
      ![
        b.openings.white,
        b.openings.black,
        b.country.name,
        b.occupation,
        b.name,
      ]
        .join(" ")
        .toLowerCase()
        .includes(filter.value.search.toLowerCase())
    ) {
      return false;
    }

    if (
      b.strength.estimated_elo < filter.value.rating[0] ||
      b.strength.estimated_elo > filter.value.rating[1]
    ) {
      // Doesn't match rating
      return false;
    }

    if (b.user == null || !b.user.hasWon) {
      if (!filter.value.unbeaten) {
        // User has not beaten this bot and we're not showing unbeaten bots
        return false;
      }
    } else {
      if (!filter.value.beaten) {
        // User as beating this bot and we're not showing beaten bots
        return false;
      }
    }

    if (!filter.value.premium && !getPremiumState() && b.premium == "closed") {
      return false;
    }

    if (filter.value.excludePlaystyles.includes(b.persona.category - 1)) {
      return false;
    }

    return true;
  });

  filteredBeaten.value = result.filter((b) => b.user?.hasWon).length;

  return result;
}

function getShowingText() {
  if (allBots.value == null) {
    return "";
  }
  let filteredCount =
    filteredBots.value == null
      ? allBots.value.length
      : filteredBots.value.length;
  let allCount = allBots.value.length;

  return `${filteredCount} bots ${
    filteredBeaten.value > 0 ? `(${filteredBeaten.value} beaten)` : ""
  } / ${allCount}`;
}

const orderBots = () => {
  if (filteredBots.value == null) {
    return;
  }

  if (sortType.value == "Rating") {
    filteredBots.value.sort(
      (a, b) => a.strength.estimated_elo - b.strength.estimated_elo
    );
  } else if (sortType.value == "Name") {
    filteredBots.value.sort((a, b) => (a.name > b.name ? 1 : -1));
  } else if (sortType.value == "Premium") {
    filteredBots.value.sort((a, b) => (a.premium < b.premium ? 1 : -1));
  } else if (sortType.value == "Playstyle") {
    filteredBots.value.sort((a, b) =>
      a.persona.category < b.persona.category ? 1 : -1
    );
  }
};
</script>

<style src="@vueform/slider/themes/default.css"></style>

<style scoped>
.filterflag {
  display: flex;

  position: absolute;
  top: -0.5rem;
  left: -0.5rem;
  color: white;
  font-size: 0.7rem;
  background: var(--clr-rect-4);
  border-radius: 50%;
  width: 1.2rem;
  height: 1.2rem;
  justify-content: center;
  align-items: center;
}
.opponentgrid {
  display: grid;
  grid-template-columns: 1fr 4fr;
  gap: 5rem;
}
.left {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  min-width: 300px;
}

.rating-slider {
  --slider-bg: var(--clr-main-lightest);
  --slider-connect-bg: var(--clr-accent);
  --slider-tooltip-bg: var(--clr-accent);
  --slider-handle-ring-color: var(--clr-accent);
  width: 12rem;
  margin: 0.5rem 0 0 0.5rem;
}

.rating-and-slider {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  margin: 0.5rem 0 1rem 0;
}

.pick-opponent-header {
  display: flex;
  justify-content: space-between;
  height: 4rem;
}

:deep(div.card .title) {
  color: var(--clr-background-detail);
  font-size: 1.5rem;
  text-align: center;
  margin-top: 1rem;
  text-decoration: none;
}

.bot-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.5rem;
}

.filter-align {
  justify-content: right;
}

@media (max-width: 992px) {
  .opponentgrid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .pick-opponent-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
  }

  .filter-align {
    justify-content: center;
  }
}
.pulsatefwd {
  -webkit-animation: pulsate-fwd 2s ease-in-out infinite both;
  animation: pulsate-fwd 2s ease-in-out infinite both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-9-25 23:8:31
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation pulsate-fwd
 * ----------------------------------------
 */
@-webkit-keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  20% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  30% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
  60% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.filter-toggle {
  outline: none;
  --toggle-width: 7rem;
  --toggle-height: 1.5rem;
  --toggle-font-size: 1rem;
  --toggle-bg-on: var(--clr-dark-accent);
  --toggle-bg-off: var(--clr-main-lighter);
  --toggle-text-on: white;
  --toggle-text-off: white;
  --toggle-border-on: var(--clr-dark-accent);
  --toggle-border-off: var(--clr-main-lighter);
  --toggle-handle-enabled: var(--clr-rect-2);
}

.filter-section {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  align-items: center;
}

@media (max-width: 576px) {
  .filter-section {
    flex-direction: column;
  }
  .pick-opponent-header {
    height: 6rem;
  }

  .bot-card-container {
    row-gap: 0.75rem;
    column-gap: 0.5rem;
  }
}
</style>
