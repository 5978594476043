<template>
  <div
    v-if="bot == null"
    style="display: flex; flex-direction: column; align-items: center"
  >
    <ProfileCircleWithPlaque
      :bot="null"
      :imageSize="isMobileWidth() ? 6 : 9"
      :font-size="'0.9rem'"
      :show-plaque="!isMobileWidth()"
      role="button"
      :style="{ marginBottom: isMobileWidth() ? '' : '1.5rem' }"
    />
    <div style="height: 2rem"></div>
  </div>
  <div
    style="display: flex; flex-direction: column; align-items: center"
    v-else
  >
    <ProfileCircleWithPlaque
      :bot="bot!"
      :imageSize="isMobileWidth() ? 6 : 9"
      style="margin-bottom: 1.5rem"
      role="button"
      :locked="isBotLocked(bot!)"
      @click="
      () => {
        ms.showBotProfile(bot!.id);
        track('suggested_bots', 'show_profile_' + trackingIdentifer, 'click');
      }
    "
    />
    <div style="height: 2rem">
      <button
        :class="'btn ' + (!isBotLocked(bot!) ? 'btn-info' : 'btn-warning') + ' bounce-top'"
        style="padding: 0.2rem 0.5rem; font-size: 1.2rem"
        @click="
      () => {
        startGame(bot!);
        track(
          'suggested_bots',
          'play_' + trackingIdentifer + (isBotLocked(bot!) ? '_locked' : ''),
          'click'
        );
      }
    "
      >
        <span v-if="!isBotLocked(bot!)" style="white-space: nowrap"
          ><i class="fa-duotone fa-swords" /> Play now</span
        >
        <span v-else><i class="fa-solid fa-crown" /> Unlock</span>
      </button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { isMobileWidth } from "@/util/util";
import { getPremiumState } from "@/util/premium";
import { track } from "@/util/tracking";
import ProfileCircleWithPlaque from "@/components/common/ProfileCircleWithPlaque.vue";
import type { PropType } from "vue";
import type { Bot } from "@/types/apitypes";
import { useModalsStore } from "@/stores/modalsStore";
import router from "@/router";

const ms = useModalsStore();

defineProps({
  bot: { type: Object as PropType<Bot> },
  trackingIdentifer: { type: String, required: true },
});

function isBotLocked(bot: Bot) {
  return !getPremiumState() && bot.premium == "closed";
}

function startGame(bot: Bot) {
  if (isBotLocked(bot)) {
    router.push({
      name: "premium",
    });
    return;
  }
  router.push({
    name: "game",
    query: {
      bid: bot.id,
      type: "casual",
      auto: "true",
    },
  });
}
</script>
